import { MapContainer } from "react-leaflet"
import { TileLayer as Basemap } from "react-leaflet"
import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import * as L from "leaflet"
import "leaflet/dist/leaflet.css"
import { DataContext } from "../../index"
import PolygonStyle from "../../styles/polygon"
import { Box } from "@mui/material"
import Legend from "./legend"
import { makeStyles } from "@mui/styles"
import { getLayerConfigs } from "../../../../../resouces/layerConfig"

const [layerConfig, layers] = getLayerConfigs(null, true)

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  legend: {
    position: "relative",
    bottom: "370px",
    left: "20px",
    zIndex: "800",
  },
})

const SearchPropertyOverviewMapLocal = (props) => {
  const { state } = useContext(DataContext)
  const classes = useStyles()
  const [map, setMap] = useState(null)
  const [mapInitialized, setMapInitialized] = useState(false)
  const [polygonLayer, setPolygonLayer] = useState(null)
  const [layer, setLayer] = useState(null)

  const mapOptions = {
    scrollWheelZoom: false,
    zoomControl: true,
    center: [35.65173947607092, 139.54482101015265],
    zoom: 18,
    minZoom: 0,
    maxZoom: 20,
    attributionControl: false,
    ...props.mapOptions,
  }

  const mapStyles = {
    width: "100%",
    height: "100%",
    border: "1px solid #333",
  }

  useEffect(() => {
    if (!map) {
      return
    }

    if (mapInitialized) {
      return
    }

    map.invalidateSize()
    let defaultLayer = L.tileLayer(
      "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
      { attribution: "", minZoom: 5, maxNativeZoom: 18, maxZoom: 28 }
    ).addTo(map)

    L.control
      .scale({ imperial: false, position: "bottomright", maxWidth: 200 })
      .addTo(map)

    setMapInitialized(true)
  }, [map])

  useEffect(() => {
    if (!mapInitialized) {
      return
    }

    if (!state.area.features[0]?.geometry?.coordinates) {
      return
    }

    let latlngs = []
    //    console.log(state.area?.features)
    state.area?.features?.forEach((feature) => {
      feature.geometry?.coordinates.forEach((coords) => {
        coords.forEach((coord) => {
          latlngs.push([coord[1], coord[0]])
        })
      })
    })
    map.fitBounds(latlngs, { maxZoom: 18, padding: [20, 20] })

    setPolygonLayer(null)
    L.geoJSON(state.area, {
      style: PolygonStyle,
      onEachFeature(feature, layer) {
        setPolygonLayer(layer)
      },
    })
  }, [mapInitialized, state.area])

  useEffect(() => {
    if (!map) {
      return
    }

    if (polygonLayer) {
      //      polygonLayer.setZIndex(500)
      polygonLayer.addTo(map)
    }

    return () => {
      if (polygonLayer) {
        map.removeLayer(polygonLayer)
      }
    }
  }, [polygonLayer])

  useEffect(() => {
    if (!map || !state.selectedLayer) {
      if (layer) {
        map.removeLayer(layer)
      }
      setLayer(null)
      return
    }

    let l = layers[state.selectedLayer]
    l.setZIndex(200)
    l.addTo(map)
    setLayer(l)

    return () => {
      if (layer) {
        map.removeLayer(layer)
        setLayer(null)
      }
    }
  }, [map, state.selectedLayer])

  const onCreatedMap = (ref) => {
    setMap(ref)
  }

  return (
    <Box className={classes.root}>
      <MapContainer
        style={mapStyles}
        whenCreated={onCreatedMap}
        {...mapOptions}
      />
      {state.selectedLayer && <Legend className={classes.legend} />}
    </Box>
  )
}

SearchPropertyOverviewMapLocal.propTypes = {
  mapOptions: PropTypes.any,
}

export default SearchPropertyOverviewMapLocal
