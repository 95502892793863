import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import Header from "../../common/header"
import { makeStyles } from "@mui/styles"
import StandardView from "../../common/view"
import SearchControl from "./search_control"
import UseSearchActualData from "./data"
import Table from "./table"
import Map from "./map"
import Widget from "./widget/index"
import Filter from "./filter"
import { defineCustomElements } from "@carto/airship-components/dist/loader"
import { useParams } from "react-router-dom"
import { Set as SetStorage } from "../../../manager/storage"
import { Alert, Backdrop, Box, CircularProgress, Snackbar } from "@mui/material"
import dayjs from "dayjs"
import { Query } from "../../../manager/carto"
import UUID from "uuidjs"
import { exportComponentAsPNG } from "react-component-export-image"
import axios from "axios"
import { RootDataContext } from "../../../App"
import root from "../../root"
defineCustomElements(window)

export const DataContext = React.createContext()

const useStyles = makeStyles({
  table: {
    width: "100vw",
    height: "40vh",
    backgroundColor: "red",
  },
  map: {
    width: "100vw",
    height: "100vw",
  },
  widget: {
    position: "absolute",
    right: "40px",
    top: "calc(60vh + 100px)",
    padding: "0 20px 20px 20px",
    backgroundColor: "rgba(255,255,255,0.6)",

    borderRadius: "8px",
    zIndex: 1000,
  },
  button_screenshot: {
    position: "fixed !important",
    width: "180px",
    height: "30px",
    bottom: "3em",
    right: "3em",
    zIndex: "3000",
    backgroundColor: "#99aabb !important",
    color: "#090b66 !important",
    cursor: "pointer",
  },
})

const SearchActual = (props) => {
  const classes = useStyles()
  const tableRef = useRef()
  const mapRef = useRef()
  const [viewMode, setViewMode] = useState("all")
  const [startExportExcel, setStartExportExcel] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [saveFailed] = useState(false)
  const [userId, setUserId] = useState(null)
  const params = useParams()

  const useSearchActualData = UseSearchActualData()
  const { state, clearData, stopLoading, setSelected } = useSearchActualData
  const { rootState } = useContext(RootDataContext)
  const filterCount = useMemo(
    () => (state.filterModel ? Object.keys(state.filterModel).length : 0),
    [state.filterModel]
  )

  useEffect(() => {
    document.title = "施工実績検索"

    if (params.uuid) {
      Query(
        `
        SELECT value FROM "${process.env.REACT_APP_CARTO_USERNAME}".saved_search_actual
        WHERE uuid = '${params.uuid}'`
      ).then((ret) => {
        const val = ret?.data?.rows[0]["value"]
        if (val) {
          clearData(JSON.parse(val))
        }
      })
    }
  }, []) // eslint-disable-line

  const onExportTableExcel = () => {
    setStartExportExcel(true)
    tableRef.current.exportExcel()
    setTimeout(() => {
      setStartExportExcel(false)
    }, 1300)
  }

  const onExportMapImage = () => {
    exportComponentAsPNG(mapRef)
      .then((ret) => {})
      .catch((e) => {})
  }

  const onSave = (adUserId) => {
    let d = dayjs().format("YYYY-MM-DD-HH-mm")
    let key = "saved_search_actual_" + d
    let value = {
      queryParams: state.queryParams,
      filterWords: state.filterWords,
      selected: state.selected,
    }
    Query(
      `
    INSERT INTO "${
      process.env.REACT_APP_CARTO_USERNAME
    }".saved_search_actual (uuid, name, value, user_id)
     VALUES ('${UUID.generate()}', '${dayjs().format(
        "YYYY年M月D日H時m分"
      )}', '${JSON.stringify(value)}', '${rootState.adUserId?.replace(/\\/, '\\\\')}')
    `
    )

    SetStorage(key, value)
    setSaveSuccess(true)
    setTimeout(() => {
      setSaveSuccess(false)
    }, 1300)
  }

  const handleClose = () => {
    stopLoading()
  }

  return (
    <DataContext.Provider value={useSearchActualData}>
      <StandardView>
        <Header title="施工実績検索" />
        <SearchControl
          viewMode={viewMode}
          onExport={onExportTableExcel}
          onSave={onSave}
        />
        {filterCount > 0 && <Filter />}
        <Table className={classes.table} viewMode={viewMode} ref={tableRef} />
        <Box ref={mapRef}>
          <Map className={classes.map} onExport={onExportMapImage} />
          {state.selected.length > 0 && (
            <div className={classes.widget}>
              <Widget className={classes.widget} />
            </div>
          )}
        </Box>
        <Backdrop
          sx={{ color: "##fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={state.isLoading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </StandardView>
      <Snackbar
        open={startExportExcel}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Excelに出力します
        </Alert>
      </Snackbar>
      <Snackbar
        open={saveSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          保存しました
        </Alert>
      </Snackbar>
      <Snackbar
        open={saveFailed}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          保存に失敗しました
        </Alert>
      </Snackbar>
    </DataContext.Provider>
  )
}

export default SearchActual
