import dayjs from "dayjs"

export const cellWidths = (worksheet, widths) => {
  for (let col of Object.keys(widths)) {
    worksheet.getColumn(col).width = widths[col]
  }
}

export const cellAligment = (worksheet, cells, aligment) => {
  for (let cell of cells) {
    worksheet.getCell(cell).alignment = aligment
  }
}

export const cellAlignmentCenter = (worksheet, cells) => {
  for (let cell of cells) {
    worksheet.getCell(cell).alignment = {
      vertical: "middle",
      horizontal: "center",
    }
  }
}

export const cellRoundBorders = (worksheet, ranges) => {
  for (let range of ranges) {
    worksheet.getCell(range).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
      bottom: { style: "thin" },
    }
  }
}

export const cellAllRoundBorderRange = (worksheet, colRow1, colRow2) => {
  let x1 = colRow1[0]
  let y1 = colRow1[1]
  let x2 = colRow2[0]
  let y2 = colRow2[1]

  for (let x = x1; x <= x2; x++) {
    for (let y = y1; y <= y2; y++) {
      worksheet.getCell(y, x).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
        bottom: { style: "thin" },
      }
    }
  }
}

export const cellWrapText = (worksheet, cells) => {
  cells.forEach((cell) => {
    worksheet.getCell(cell).alignment.wrapText = true
  })
}

export const cellMergeRanges = (worksheet, merges) => {
  for (const range of merges) {
    //worksheet.merge(range)
    worksheet.mergeCells(range)
  }
}

export const cellValues = (worksheet, values) => {
  for (const key of Object.keys(values)) {
    if (values[key] instanceof Object) {
      let c = worksheet.getCell(key)
      let v = values[key](c, key)
      if (v) {
        worksheet.getCell(key).value = v
      }
    } else {
      worksheet.getCell(key).value = values[key]
    }
  }
}

export const exportExcel = async (workbook, name) => {
  const uint8Array = await workbook.xlsx.writeBuffer()
  const blob = new Blob([uint8Array], { type: "application/octet-binary" })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = name
    ? `${name}.xlsx`
    : `物件概要書_${dayjs().format("YYYY年MM月DD日HH時mm分")}作成】.xlsx`
  a.click()
  a.remove()
}
