import React, { useEffect, useState } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import Root from "./views/root"
import Search from "./views/search"
import SearchActual from "./views/search/actual"
import SearchProperty from "./views/search/property"
import UseRootData from "./data"
import axios from "axios"
import { Box, Typography } from "@mui/material"

export const RootDataContext = React.createContext()

const App = () => {
  const useRootData = UseRootData()
  const { rootState, setAdData } = useRootData
  const [loading, setLoading] = useState(false)
/*
  useEffect(() => {
    axios.get(process.env.REACT_APP_AUTH_STATUS_ENDPOINT).then((res) => {
      if (!res.data) {
        setLoading(false)
        return
      }
      let lines = res.data.split("\n")
      setAdData(
        Object.fromEntries(
          lines.map((l) => {
            let kv = l.split("\t")
            return [kv[0], kv[1]]
          })
        )
      )
    })

  }, [])

  useEffect(() => {
    setLoading(!rootState.adUserId)
  }, [rootState.adUserId])
*/
  return (
    <RootDataContext.Provider value={useRootData}>
      {loading && (
        <Box style={{ width: "100vw", height: "100vh", display: "flex" }}>
          <Typography
            style={{
              color: "black",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "18px",
            }}
          >
            読込中...
          </Typography>
        </Box>
      )}
      {!loading && (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Root} />
            <Route exact path="/search" component={Search} />
            <Route
              exact
              path="/search/actual/:uuid?"
              component={SearchActual}
            />
            <Route
              exact
              path="/search/property/:uuid?"
              component={SearchProperty}
            />
          </Switch>
        </BrowserRouter>
      )}
    </RootDataContext.Provider>
  )
}

export default App
