import { makeStyles } from "@mui/styles"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import {
  MapContainer,
  TileLayer,
  LayersControl,
  Marker,
  Popup,
} from "react-leaflet"
import * as L from "leaflet"
import "leaflet/dist/leaflet.css"
import "leaflet-draw"
import "leaflet-draw/dist/leaflet.draw.css"
import "turf-union"
import "../../../../resouces/leaflet-draw/locale.ja"
import * as turf from "@turf/turf"
import { DataContext } from "../index"
import icon2x from "leaflet/dist/images/marker-icon-2x.png"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"

import CartodbLayer from "../../../../manager/cartodb/layer"
import { Box, Link, Typography } from "@mui/material"
import layer from "../../../../manager/cartodb/layer"
import PolygonStyle from "../styles/polygon"
import { getLayerConfigs } from "../../../../resouces/layerConfig"
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    margin: "12px 0",
    position: "relative",
  },
  layers: {
    position: "absolute",
    zIndex: 1000,
    width: "200px",
    padding: "20px",
    color: "white",
    backgroundColor: "#303030a8",
    bottom: "16px",
    left: "16px",
    borderRadius: "8px",
    border: "1px solid #999",
    boxShadow: "1px 1px 5px #9f9f9f91",
    fontSize: "12px",
  },
  legend: {
    position: "absolute",
    zIndex: 1000,
    width: "200px",
    padding: "20px",
    color: "white",
    backgroundColor: "#303030a8",
    borderRadius: "8px",
    border: "1px solid #999",
    boxShadow: "1px 1px 5px #9f9f9f91",
    fontSize: "9px",
    pointerEvents: "none",
    right: "58px",
    top: "100px",
  },
  legend_color: {
    width: "18px",
  },
  source: {
    padding: "4px",
    position: "absolute",
    bottom: 0,
    right: "46px",
    backgroundColor: "rgba(255,255,255,0.56)",
    zIndex: 400,
    maxWidth: "600px",
  },
})

const DefaultIcon = L.icon({
  iconRetinaUrl: icon2x,
  iconUrl: icon,
  shadowUrl: iconShadow,
})
L.Marker.prototype.options.icon = DefaultIcon

const baseLayers = {
  "地理院タイル(標準)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  "地理院タイル(淡色)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  "地理院タイル(写真)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
}

const [layerConfig, layers] = getLayerConfigs(null, true)

const SearchPropertyMap = (props) => {
  const { state, setArea, setMapPoint, setSelectedLayer } =
    useContext(DataContext)
  const [map, setMap] = useState(null)
  const [drawItems, setDrawItems] = useState(null)
  const [drawControl, setDrawControl] = useState(null)
  const [placeMarker, setPlaceMarker] = useState(null)
  const [placePosition, setPlacePosition] = useState([0, 0])
  const [placeName, setPlaceName] = useState(null)
  const [legend, setLegend] = useState(null)
  const [drawEditing, setDrawEditing] = useState(null)
  const [sourceTitle, setSourceTitle] = useState(null)
  const [sourceSubTitle, setSourceSubTitle] = useState(null)
  const [sourceLink, setSourceLink] = useState(null)
  const classes = useStyles()

  let mapInitialLoaded = false
  let mapControlInitialized = false
  let drawItemInitialized = false

  let mapOptions = {
    center: [35.65173947607092, 139.54482101015265],
    zoom: 11,
    minZoom: 0,
    maxZoom: 20,
    attributionControl: false,
    ...props.mapOptions,
  }

  const mapStyles = {
    width: "46vw",
    height: "calc(100vh - 160px)",
    margin: "0",
  }

  useEffect(() => {
    if (!map) {
      return
    }
    map.invalidateSize()

    if (!mapInitialLoaded) {
      let latlngs = []
      state.area?.features?.forEach((feature) => {
        feature.geometry?.coordinates.forEach((coords) => {
          coords.forEach((coord) => {
            latlngs.push([coord[1], coord[0]])
          })
        })
      })
      if (latlngs.length > 0) {
        map.fitBounds(latlngs, { maxZoom: 16, padding: [20, 20] })
      }
    }
    mapInitialLoaded = true

    if (mapControlInitialized) {
      return
    }

    mapControlInitialized = true

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    })

    const scaleControl = L.control
      .scale({ position: "topright", imperial: false, maxWidth: 200 })
      .addTo(map)
    let items = new L.featureGroup().addTo(map)
    let control = new L.Control.Draw({
      draw: {
        marker: false,
        polyline: false,
        rectangle: false,
        circle: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: items,
      },
      position: "topleft",
    }).addTo(map)
    setDrawItems(items)
    setDrawControl(control)

    let defaultLayer = L.tileLayer(
      "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
      { attribution: "", minZoom: 5, maxNativeZoom: 18, maxZoom: 28 }
    )

    L.control.layers(baseLayers, {}, { position: "topright" }).addTo(map)
    map.addLayer(baseLayers["地理院タイル(淡色)"])

    map.on("contextmenu", (e) => {
      console.log(e)
      if (!drawEditing) {
        setMapPoint([e.latlng.lat, e.latlng.lng])
      }
    })
  }, [map])

  useEffect(() => {
    if (!drawItems || !state.area) {
      return
    }
    if (drawItemInitialized) {
      return
    }
    drawItemInitialized = true
    L.geoJSON(state.area, {
      style: PolygonStyle,
      onEachFeature: (feature, layer) => {
        drawItems.addLayer(layer)
      },
    })
  }, [drawItems, state.area])

  useEffect(() => {
    if (!map) {
      return
    }

    map
      .on("draw:drawstart", () => {
        //        console.log("[Draw]", "draw start")
        setDrawEditing(true)
      })
      .on("draw:drawstop", () => {
        //        console.log("[Draw]", "draw stop")
        setDrawEditing(false)
      })
      .on("draw:editstart", () => {
        //        console.log("[Draw]", "edit start")
        setDrawEditing(true)
      })
      .on("draw:editstop", () => {
        //        console.log("[Draw]", "edit stop")
        setDrawEditing(false)
      })
      .on("draw:deletestart", () => {
        //        console.log("[Draw]", "delete start")
        setDrawEditing(true)
      })
      .on("draw:deletestop", () => {
        //        console.log("[Draw]", "delete stop")
        setDrawEditing(false)
      })
      .on("draw:created", (e) => {
        let layer = e.layer
        let newLayer = e.layer.toGeoJSON()

        let feature = drawItems.toGeoJSON().features.shift()
        if (!feature) {
          layer.setStyle(PolygonStyle)
          drawItems.clearLayers()
          drawItems.addLayer(layer)
          setArea(drawItems.toGeoJSON())
          return
        }

        newLayer = turf.union(newLayer, feature)
        if (newLayer.geometry.type === "MultiPolygon") {
          layer.setStyle(PolygonStyle)
          drawItems.clearLayers()
          drawItems.addLayer(layer)
          setArea(drawItems.toGeoJSON())
          return
        }
        L.geoJSON(newLayer, {
          style: PolygonStyle,
          onEachFeature: (feature, layer) => {
            drawItems.clearLayers()
            drawItems.addLayer(layer)
            setArea(drawItems.toGeoJSON())
          },
        })
      })
      .on("draw:edited", (e) => {
        setArea(drawItems.toGeoJSON())
      })
      .on("draw:deleted", (e) => {
        setArea(drawItems.toGeoJSON())
      })
  }, [drawControl])

  useEffect(() => {
    console.log("[Map]", "Initialized", state.initialized, state.area)
  }, [state.initialized])

  useEffect(() => {
    if (drawEditing) {
      setMapPoint(null)
    }
  }, [drawEditing])

  useEffect(() => {
    if (!map) {
      return
    }
    let location = state.place?.geometry?.location
    if (!location) {
      placeMarker && map.removeLayer(placeMarker)
      setPlaceMarker(null)
      setPlaceName(null)
      return
    }
    let ll = [location.lat(), location.lng()]
    let m = L.marker(ll)
    map.flyTo(ll, 15)
    m.addTo(map)
    setPlacePosition(ll)
    setPlaceName(state.place.formatted_address)
  }, [map, state.place])

  useEffect(() => {
    if (!map || !state.selectedLayer) {
      return
    }

    let layer = layers[state.selectedLayer]
    layer.setZIndex(100)
    layer.addTo(map)

    setLegend(layerConfig[state.selectedLayer]?.legend)

    let attribute = layerConfig[state.selectedLayer]?.attribute
    if (attribute) {
      setSourceTitle(attribute?.title)
      setSourceLink(attribute?.url)
      setSourceSubTitle(attribute?.sub_title)
    }

    return () => {
      map.removeLayer(layer)
      setLegend(null)
    }
  }, [map, state.selectedLayer])

  const onCreatedMap = (ref) => {
    setMap(ref)
  }

  const onClickLayer = (e) => {
    //    setSelectedLayer(null)
    setSelectedLayer(e.target.value)
  }

  return (
    <div className={classes.root}>
      <MapContainer
        style={mapStyles}
        whenCreated={onCreatedMap}
        {...mapOptions}
      >
        <Marker position={placePosition}>
          <Popup>{placeName}</Popup>
        </Marker>
        {state.map_point && state.map_point_info && (
          <Popup position={state.map_point}>
            <table>
              <tr>
                <th>座標</th>
                <td>
                  {state.map_point[0].toFixed(5)},
                  {state.map_point[1].toFixed(5)}
                </td>
              </tr>
              <tr>
                <th>住所</th>
                <td>{state.map_point_info.address ?? "--"}</td>
              </tr>
              <tr>
                <th>3次メッシュ</th>
                <td>{state.map_point_info.mesh3 ?? "--"}</td>
              </tr>
              <tr>
                <th>土砂災害警戒区域</th>
                <td>{state.map_point_info.dosyasaigai ?? "--"}</td>
              </tr>
              <tr>
                <th>津波浸水想定</th>
                <td>{state.map_point_info.tsunami ?? "--"}</td>
              </tr>
              <tr>
                <th>年間雨量</th>
                <td>{state.map_point_info.rain ?? "--"}</td>
              </tr>
              <tr>
                <th>PML(振幅率)</th>
                <td>
                  {state.map_point_info.pml?.amplification_factor ?? "--"}
                </td>
              </tr>
              <tr>
                <th>PML(RC造旧耐震)</th>
                <td>{state.map_point_info.pml?.rc_kyu ?? "--"}</td>
              </tr>
              <tr>
                <th>PML(RC造新耐震)</th>
                <td>{state.map_point_info.pml?.rc_shin ?? "--"}</td>
              </tr>
              <tr>
                <th>PML(S造旧耐震)</th>
                <td>{state.map_point_info.pml?.s_kyu ?? "--"}</td>
              </tr>
              <tr>
                <th>PML(S造新耐震)</th>
                <td>{state.map_point_info.pml?.s_shin ?? "--"}</td>
              </tr>
              <tr>
                <th>地表面最大速度</th>
                <td>{state.map_point_info.pml?.surface_velocity ?? "--"}</td>
              </tr>
            </table>
          </Popup>
        )}
      </MapContainer>
      {layers && (
        <Box className={classes.layers}>
          <div>
            <label>
              <input
                type="radio"
                name="layers"
                checked={!state.selectedLayer && "checked"}
                onChange={() => setSelectedLayer(null)}
              />
              なし
            </label>
          </div>
          {Object.keys(layers).map((key) => {
            return (
              <div key={key}>
                <label>
                  <input
                    name="layers"
                    type="radio"
                    value={key}
                    checked={state.selectedLayer == key}
                    onChange={onClickLayer}
                  />
                  {key}
                </label>
              </div>
            )
          })}
        </Box>
      )}
      {legend && (
        <Box className={classes.legend}>
          <table>
            {legend.map((l) => {
              return (
                <tr key={l.label}>
                  <td
                    className={classes.legend_color}
                    style={{ backgroundColor: l.color }}
                  >
                    &nbsp;
                  </td>
                  <td className={classes.legend_label}>{l.label}</td>
                </tr>
              )
            })}
          </table>
        </Box>
      )}
      {sourceTitle && (
        <Box className={classes.source}>
          {sourceLink && (
            <Typography variant="subtitle2" align="right">
              出典：
              <Link
                target="_blank"
                href="https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A31-v2_2.html"
              >
                {sourceTitle}
                <OpenInNewIcon fontSize="small" style={{verticalAlign: "sub"}} />
              </Link>
            </Typography>
          )}
          {!sourceLink && (
            <Typography variant="subtitle2" align="right">
              出典：{sourceTitle}
            </Typography>
          )}
          {sourceSubTitle && (
            <Typography variant="caption">{sourceSubTitle}</Typography>
          )}
        </Box>
      )}
    </div>
  )
}

SearchPropertyMap.propTypes = {
  mapOptions: PropTypes.any,
}

export default SearchPropertyMap
