import React, { useState } from "react"
import { DataContext } from "../index"
import { useContext, useEffect } from "react"
import { Box, Checkbox, Typography } from "@mui/material"
import { Query } from "../../../../manager/carto"
import { sprintf } from "sprintf-js"
import { Check } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"

import "./index.css"
import { setTransform } from "leaflet/src/dom/DomUtil"

const useStyles = makeStyles({
  table: {
    display: "flex",
    flexDirection: "row",
  },
  head: {
    width: "120px",
    marginTop: "12px",
  },
  checkbox: {
    padding: "3px",
  },
  table_tr: {
    cursor: "pointer",
  },
  td_checkbox: {
    width: "30px",
  },
  td_name: {
    width: "100%",
    display: "flex",
    gap: "4px",
  },
  td_marker: {
    height: "24px",
  }
})

const PropertyTransit = (props) => {
  const classes = useStyles()
  const { state, setTransitTrainData, setTransitIcData, setSelectedTransitId } =
    useContext(DataContext)

  useEffect(() => {
    getTransit()
  }, [state.area])

  const getTransit = async () => {
    if ((state.area?.features?.length ?? 0) === 0) {
      setTransitIcData(null)
      setTransitTrainData(null)
      setSelectedTransitId([])
      return
    }
    let geometry = state.area.features[0].geometry

    await Query(`
SELECT 
  cartodb_id as id,
  ST_X(the_geom) AS longitude,
  ST_Y(the_geom) AS latitude,
  name,
  ST_Distance(
    the_geom_webmercator,
    ST_Transform(ST_Centroid(
      ST_GeomFromGeoJSON('${JSON.stringify(geometry)}')
    ), 3857)
  ) AS length
FROM ic
ORDER BY
  ST_Distance(
    the_geom_webmercator,
    ST_Transform(ST_Centroid(
      ST_GeomFromGeoJSON('${JSON.stringify(geometry)}')
    ), 3857)
  )
LIMIT 3    
    `).then((res) => {
      let val = res.data.rows.map((v) => {
        return {
          id: v.id,
          name: v.name,
          latitude: v.latitude,
          longitude: v.longitude,
          length: getLengthText(v.length),
        }
      })
      setTransitIcData(val)
    })

    await Query(`
  SELECT 
    cartodb_id as id,
    ST_X(the_geom) AS longitude,
    ST_Y(the_geom) AS latitude,
    name || '(' || rosen_name || ')' as name,
    ST_Distance(
      the_geom_webmercator,
      ST_Transform(ST_Centroid(
        ST_GeomFromGeoJSON('${JSON.stringify(geometry)}')
      ), 3857)
    ) AS length
FROM station
ORDER BY
  ST_Distance(
    the_geom_webmercator,
    ST_Transform(ST_Centroid(
      ST_GeomFromGeoJSON('${JSON.stringify(geometry)}')
    ), 3857)
  ) LIMIT 3
`).then((res) => {
      let val = res.data.rows.map((v) => {
        return {
          id: v.id,
          name: v.name,
          latitude: v.latitude,
          longitude: v.longitude,
          length: getLengthText(v.length),
        }
      })
      setTransitTrainData(val)
    })
  }

  const getLengthText = (length) => {
    if (length > 11000) {
      return Math.floor(length / 1000) + "km"
    } else if (length > 1000) {
      return (length / 1000).toFixed(2) + "km"
    }
    return Math.round(length) + "m"
  }

  const onRowClicked = (id) => {
    let s = state.selectedTransitId
    if (s.includes(id)) {
      s = s.filter((i) => {
        return i !== id
      })
    } else {
      s.push(id)
    }
    setSelectedTransitId([...s])
  }

  return (
    <>
      {state.transitIcData && (
        <Box className={classes.table}>
          <Box className={classes.head}>交通（IC）</Box>
          <table id="transit_table"
            cellSpacing="0"
            cellPadding="0"
            style={{ width: "100%", marginTop: "8px" }}
          >
            <tbody>
              {state.transitIcData.map((v) => {
                return (
                  <tr
                    key={v.id}
                    className={classes.table_tr}
                    onClick={() => onRowClicked(v.id)}
                  >
                    <td className={classes.td_checkbox}>
                      <Checkbox
                        style={{ margin: "3px", padding: 0 }}
                        value={v.id}
                        checked={state.selectedTransitId.includes(v.id)}
                      />
                    </td>
                    <td className={classes.td_name}>
                      <Typography>
                        {v.name} ({v.length})
                      </Typography>
                      {state.selectedTransitId.includes(v.id) && (
                        <img className={classes.td_marker} src={"/markers/marker_" + (state.selectedTransitId.indexOf(v.id) + 1) + ".png"} />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Box>
      )}
      {state.transitTrainData && (
        <Box className={classes.table}>
          <Box className={classes.head}>交通（電車）</Box>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ width: "100%", marginTop: "8px" }}
          >
            <tbody>
              {state.transitTrainData.map((v) => {
                return (
                  <tr
                    key={v.id}
                    className={classes.table_tr}
                    onClick={() => onRowClicked(v.id)}
                  >
                    <td className={classes.td_checkbox}>
                      <Checkbox
                        style={{ margin: "3px", padding: 0 }}
                        value={v.id}
                        checked={state.selectedTransitId.includes(v.id)}
                      />
                    </td>
                    <td className={classes.td_name}>
                      <Typography>
                        {v.name} ({v.length})
                      </Typography>
                      {state.selectedTransitId.includes(v.id) && (
                          <img className={classes.td_marker} src={"/markers/marker_" + (state.selectedTransitId.indexOf(v.id) + 1) + ".png"} />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Box>
      )}
    </>
  )
}

export default PropertyTransit
