import carto from "@carto/carto.js"

const SearchActualDataState = {
  uuid: null,
  isLoading: false,
  stopLoadCallback: null,
  error: null,
  rows: [],
  data: [],
  queryParams: [],
  totalCount: 0,
  filterWords: null,
  selected: [],
  viewMode: "all",
  filterModel: {},

  focusedPoint: null,

  // carto client
  cartoClient: new carto.Client({
    apiKey: process.env.REACT_APP_CARTO_API_KEY,
    username: process.env.REACT_APP_CARTO_USERNAME,
    serverUrl: process.env.REACT_APP_CARTO_SERVER_URL,
  }),
  // map options
  mapOptions: {
    center: [35.70219248461344, 139.75941194413406],
    zoom: 11,
    checked: false,
    minZoom: 0,
    maxZoom: 20,
    attributionControl: false,
    gestureHandling: true,
  },
  // map source
  mapSource: new carto.source.SQL(
    "SELECT 1 as cartodb_id, null as the_geom_webmercator, null as completion_year"
  ),
  dataType: ["kenchiku"],
}
export default SearchActualDataState
