import React from "react"
import PropTypes from "prop-types"
import { Box, CssBaseline } from "@mui/material"

const StandardView = (props) => {
  const { children } = props

  return (
    <React.StrictMode>
      <CssBaseline />
      <Box
        {...props}
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "1200px",
        }}
      >
        {children}
      </Box>
    </React.StrictMode>
  )
}

StandardView.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  isLoading: PropTypes.bool,
}

StandardView.defaultProps = {
  isLoading: false,
}

export default StandardView
