import initialState from "./state"
import reducer, { ActionType } from "./reducer"
import { useReducer } from "react"
import { Query, SyncARow } from "../../../../manager/carto"
import { Address } from "../../../../manager/geocode"

const UseSearchPropertyData = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  const fetch = () => {
    dispatch({ type: ActionType.FetchStarted })

    dispatch({ type: ActionType.FetchCompleted, error: null })
  }

  const setArea = async (area) => {
    dispatch({ type: ActionType.SetArea, value: area })

    let info = {}

    if (area?.features?.length == 0) {
      return
    }
    if (!area.features[0].geometry) {
      return
    }
    let geometry = area.features[0].geometry

    // 重心の取得
    info["center"] = await _calcCentroid(geometry)

    // 3次メッシュの取得
    info["mesh3"] = await _calcMesh3(info["center"])

    await Promise.all([
      (async () => {
        info["area"] = await _calcArea(geometry)
      })(),
      (async () => {
        info["address"] = await _calcAddress(info["center"])
      })(),
      (async () => {
        let y = await _calcYoto(geometry)
        console.log("[AreaInfo]", "get yoto", y)
        info["yoto"] = y?.map((v) => v.yoto)
        info["yoseki"] = y?.map((v) => v.yoseki_rate)
        info["kenpe"] = y?.map((v) => v.kenpe_rate)
        //        info["yoto"] = await _calcYoto(geometry)
      })(),
      (async () => {
        info["dosyasaigai"] = await _calcDosyasaigai(geometry)
      })(),
      (async () => {
        info["tsunami"] = await _calcTsunami(geometry)
      })(),
      (async () => {
        info["rain"] = await _calcRain(info["center"])
      })(),
      (async () => {
        info["pml"] = await _calcPml(info["center"])
      })(),
      (async () => {
        info["kouzui"] = await _calcKouzui(info["center"])
      })(),
      (async () => {
        info["tatsumaki"] = await _calcTatsumaki(info["center"])
      })(),
    ])
    console.log("[AreaInfo]", "update area info", info)
    dispatch({ type: ActionType.SetAreaInfo, value: info })
  }

  const setMapPoint = async (latlng) => {
    dispatch({ type: ActionType.SetMapPoint, value: latlng })

    if (!latlng) {
      dispatch({ type: ActionType.SetMapPointInfo, value: null })
      return
    }

    let info = {}

    // 重心の取得
    info["location"] = latlng.join(",")

    // 3次メッシュの取得
    info["mesh3"] = await _calcMesh3(latlng)

    await Promise.all([
      (async () => {
        info["address"] = await _calcAddress(latlng)
      })(),
      (async () => {
        let y = await _calcPointYoto(latlng)
        info["yoto"] = y.yoto
        info["yoseki"] = y.yoseki_rate
        info["kenpe"] = y.kenpe_rate
        //        info["yoto"] = await _calcPointYoto(latlng)
      })(),
      (async () => {
        info["dosyasaigai"] = await _calcPointDosyasaigai(latlng)
      })(),
      (async () => {
        info["tsunami"] = await _calcPointTsunami(latlng)
      })(),
      (async () => {
        info["rain"] = await _calcRain(latlng)
      })(),
      (async () => {
        info["pml"] = await _calcPml(latlng)
      })(),
      (async () => {
        info["kouzui"] = await _calcKouzui(latlng)
      })(),
      (async () => {
        info["tatsumaki"] = await _calcTatsumaki(latlng)
      })(),
    ])

    dispatch({ type: ActionType.SetMapPointInfo, value: info })
  }

  const clearData = (data, callback) => {
    dispatch({ type: ActionType.SetInitialized, value: false })
    dispatch({ type: ActionType.Clear, value: data })
    dispatch({ type: ActionType.SetInitialized, value: true })
    callback && callback()
  }

  const setCustomer = (customer) => {
    dispatch({ type: ActionType.SetCustomer, value: customer })
  }

  const setPlace = (place) => {
    dispatch({ type: ActionType.SetPlace, value: place })
  }

  const setTransitTrainData = (data) => {
    dispatch({ type: ActionType.SetTransitTrainData, value: data })
  }

  const setTransitIcData = (data) => {
    dispatch({ type: ActionType.SetTransitIcData, value: data })
  }

  const setSelectedTransitId = (ids) => {
    dispatch({ type: ActionType.SetSelectedTransitId, value: ids })
  }

  const setSelectedLayer = (layerName) => {
    dispatch({ type: ActionType.SetSelectedLayer, value: layerName })
  }

  const _calcArea = async (geom) => {
    let res = await SyncARow(`
SELECT
ST_Area(
  geography(
    ST_SetSRID(
      ST_GeomFromGeoJSON('${JSON.stringify(geom)}')
    ,4326)
  )
,true) AS area
`)
    return res["area"].toFixed(2) + "㎥"
  }

  const _calcCentroid = async (geom) => {
    let res = await SyncARow(`
      SELECT ST_X(center) AS longitude, ST_Y(center) AS latitude
      FROM (
             SELECT ST_Centroid(ST_GeomFromGeoJSON('${JSON.stringify(
               geom
             )}')) AS center
           ) AS foo
      `)
    return [res["latitude"], res["longitude"]]
  }

  const _calcMesh3 = async (latlng) => {
    let res = await SyncARow(`
      SELECT meshcode FROM mesh_3 WHERE ST_Contains(the_geom,ST_SetSRID(ST_MakePoint( ${latlng[1]}, ${latlng[0]}), 4326))    
    `)

    return res?.meshcode
  }

  const _calcAddress = async (latlng) => {
    let res = await Address(latlng[0], latlng[1])
    let data = res.data?.result
    let addr = []
    if (data) {
      addr.push(data.prefecture?.pname?.trim() ?? "")
      addr.push(data.municipality?.mname?.trim() ?? "")
      if (data.local && data.local.length > 0) {
        addr.push(data.local[0].section?.trim() ?? "")
      }
    }
    return addr.join("") + "付近"
  }

  const _calcYoto = async (geom) => {
    let jsonString = JSON.stringify(geom)
    let res = await Query(`
WITH cte AS (
  SELECT
    youto_code AS type,
    youto_name AS name,
    AVG(kenpei) AS kenpei_rate,
    AVG(youseki) AS youseki_rate,
    SUM(ST_Area(
        geography(
            ST_Transform(
                ST_Intersection(
                    ST_Buffer(the_geom_webmercator,10),
                    ST_Transform(ST_GeomFromGeoJSON('${jsonString}'),3857)
                ),
                4326
            ) 
        )
    )) AS area
  FROM "bcp-app".youto2019
  WHERE
    ST_Intersects(
      the_geom,
      ST_GeomFromGeoJSON('${jsonString}')
    )
  GROUP BY 1, 2
)
SELECT
  area / sum_area AS raito,
  kenpei_rate AS kenpe_rate,
  youseki_rate AS yoseki_rate,
  area,
  type,
  name
FROM cte
LEFT JOIN (SELECT SUM(area) AS sum_area FROM cte) AS s
ON TRUE
ORDER BY area DESC
LIMIT 3
    `)

    if ((res.data?.rows?.length ?? 0) == 0) {
      return null
    }
    let other = 1
    let names = []
    let result = res.data.rows.flatMap((v) => {
      if (names.includes(v.yoto)) {
        return []
      }
      names.push(v.yoto)
      other -= v.raito
      return [
        {
          kenpe_rate: v.kenpe_rate,
          yoseki_rate: v.yoseki_rate,
          yoto: `${v.name} (${Math.round(v.raito * 100)}%)`,
        },
      ]
    })
    if (Math.round(other) > 0) {
      result.push({
        yoto: `その他 (${Math.round(other * 100)}%)`,
      })
    }
    console.log("[Get yoto]", result)
    return result
  }

  const _calcDosyasaigai = async (geom) => {
    let jsonString = JSON.stringify(geom)
    let res = await Query(`
SELECT 
  DISTINCT
    a33_001_data || ' (' || a33_002_data || ')' AS text
FROM "${process.env.REACT_APP_CARTO_USERNAME}".dosyasaigaikeikaikuiki
WHERE ST_intersects(the_geom, ST_GeomFromGeoJSON('${jsonString}'))    
    `)

    let val = res?.data?.rows?.map((v) => {
      return v.text.replace("(指定済)", "")
    })

    return val
  }

  const _calcKouzui = async (latlng) => {
    let res = await SyncARow(`
    SELECT inundation_depth_rank AS value
    FROM "${process.env.REACT_APP_CARTO_USERNAME}".inundation_area 
    WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
    `)
    return res?.value
  }

  const _calcTsunami = async (geom) => {
    let jsonString = JSON.stringify(geom)
    let res = await SyncARow(`
SELECT inundation_depth_rank AS name
FROM "${process.env.REACT_APP_CARTO_USERNAME}".tsunami_inundation
WHERE
 ST_Intersects(the_geom, ST_GeomFromGeoJSON('${jsonString}'))
ORDER BY 
CASE inundation_depth_rank
WHEN '0.01m以上 ～ 0.3m未満' THEN 1
WHEN '0.3m以上 ～ 1m未満' THEN 2
WHEN '1m以上 ～ 2m未満' THEN 3
WHEN '2m以上 ～ 3m未満' THEN 4
WHEN '3m以上 ～ 4m未満' THEN 5
WHEN '4m以上 ～ 5m未満' THEN 6
WHEN '5m以上 ～ 10m未満' THEN 7
WHEN '10m以上 ～ 20m未満' THEN 8
WHEN '20m以上' THEN 9
END DESC
LIMIT 1
    `)
    return res?.name
  }

  const _calcRain = async (latlng) => {
    let res = await SyncARow(`
    SELECT g02_014 AS rain FROM rain WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
    `)

    return res?.rain
  }



  const _calcPml = async (latlng) => {
    let res = await SyncARow(`
    SELECT 
    amplification_factor,
    surface_velocity_475y AS surface_velocity,
    pml_rc_kyutaishin_is0_4 AS rc_kyu,
    pml_rc_shintaishin_is0_6 AS rc_shin,
    pml_s_kyutaishin_is0_4 AS s_kyu,
    pml_s_shintaishin_is0_6 AS s_shin
    FROM "${process.env.REACT_APP_CARTO_USERNAME}".pml
    WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
    `)

    return res
  }

  const _calcPointYoto = async (latlng) => {
    let res = await SyncARow(`
    SELECT 
           a29_005 AS yoto,
           a29_006 AS kenpe_rate,
           a29_007 AS yoseki_Rate,
    FROM "${process.env.REACT_APP_CARTO_USERNAME}".youto_chiiki_2011 WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
    `)
    return {
      yoto: res.yoto,
      kenpe_rate: res.kenpe_rate,
      yoseki_rate: res.yoseki_rate,
    }
  }

  const _calcPointDosyasaigai = async (latlng) => {
    let res = await SyncARow(`
    SELECT a33_001_data as name, a33_002_data as type 
    FROM "${process.env.REACT_APP_CARTO_USERNAME}".dosyasaigaikeikaikuiki 
    WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))    
    `)
    if (!res) {
      return null
    }
    return `${res.name}(${res.type.replace("(指定済)", "")})`
  }

  const _calcPointTsunami = async (latlng) => {
    let res = await SyncARow(`
    SELECT inundation_depth_rank AS rank 
    FROM "${process.env.REACT_APP_CARTO_USERNAME}".tsunami_inundation 
    WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
    `)
    return res?.rank
  }

  const _calcTatsumaki = async (latlng) => {
    let res = await SyncARow(`
    SELECT tatsumaki_count AS value 
    FROM "${process.env.REACT_APP_CARTO_USERNAME}".tatsumaki 
    WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
    `)
    return res?.value
  }

  return {
    state,
    fetch,
    clearData,
    setCustomer,
    setPlace,
    setArea,
    setMapPoint,
    setTransitIcData,
    setTransitTrainData,
    setSelectedTransitId,
    setSelectedLayer,
  }
}

export default UseSearchPropertyData
