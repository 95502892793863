import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { MapContainer } from "react-leaflet"
import * as L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling"
import "leaflet/dist/leaflet.css"
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css"
import { makeStyles } from "@mui/styles"
import "leaflet-easybutton"
import "leaflet-easybutton/src/easy-button.css"
import { Alert, Box, Link, Snackbar, Typography } from "@mui/material"
import { DataContext } from "../index"
import * as wkx from "wkx"
import carto from "@carto/carto.js"
import style from "./layer/style"
import PropTypes from "prop-types"
import { Address } from "../../../../manager/geocode"
import { First, One, SyncARow } from "../../../../manager/carto"
import { getLayerConfigs } from "../../../../resouces/layerConfig"
import { Get } from "../../../../manager/storage"
import columnDef from "../table/columnDef"

const useStyles = makeStyles({
  root: {
    width: "calc(100% - 40px)",
    margin: "20px",
    height: "800px",
    overflow: "hidden",
    position: "relative",
  },
  map_photo_button: {
    display: "block !important",
    position: "relative !important",
    top: "0 !important",
    left: "0 !important",
  },
  layerControl: {
    zIndex: 700,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "200px",
    left: "10px",
    bottom: "650px",
    gap: "8px",
  },
  layers: {
    padding: "20px",
    color: "white",
    backgroundColor: "#303030a8",
    left: "20px",
    borderRadius: "8px",
    border: "1px solid #999",
    boxShadow: "1px 1px 5px #9f9f9f91",
    fontSize: "12px",
  },
  legend: {
    padding: "20px",
    color: "white",
    backgroundColor: "#303030a8",
    borderRadius: "8px",
    border: "1px solid #999",
    boxShadow: "1px 1px 5px #9f9f9f91",
    fontSize: "12px",
  },
  legend_color: {
    width: "18px",
  },
  source: {
    padding: "4px",
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(255,255,255,0.56)",
    zIndex: 400,
  },
})

const CARTO_BASEMAP =
  "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png"

const [layerConfig, layers] = getLayerConfigs(null, true)
const baseLayers = {
  "地理院タイル(標準)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  "地理院タイル(淡色)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  "地理院タイル(写真)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
}

let infoWindowTimer = null
let indicatorPopup = null

const SearchActualMap = (props) => {
  const classes = useStyles()
  const rootRef = useRef()
  const css = useMemo(() => new carto.style.CartoCSS(style))

  const { state, setMapOptions } = useContext(DataContext)
  const [cameraButton] = useState(null)
  const [generatingImage] = useState(false)
  const [selectedLayer, setSelectedLayer] = useState(null)
  const [focusedMarker, setFocusedMarker] = useState(null)
  const [legend, setLegend] = useState(null)
  const [clickedMapData, setClickedMapData] = useState(null)
  const [clickedPointData, setClickedPointData] = useState(null)
  const [sourceTitle, setSourceTitle] = useState(null)
  const [sourceSubTitle, setSourceSubTitle] = useState(null)
  const [sourceLink, setSourceLink] = useState(null)
  const mapRef = useRef(null)

  const parseHeaderName = (columns) => {
    let values = {}
    for (let col of columns) {
      console.log(col)
      if (col.field && col.headerName) {
        values[col.field] = col.headerName
      }
      if (col.children) {
        let children = parseHeaderName(col.children)
        values = {
          ...values,
          ...children,
        }
      }
    }
    return values
  }
  const columnHeaderNames = useMemo(() => {
    return parseHeaderName(columnDef)
  }, [])

  const layer = useMemo(() => {
    if (!state.mapSource) {
      if (layer) {
        state.cartoClient.removeLayer(layer)
      }
      return
    }
    let l = new carto.layer.Layer(state.mapSource, css)
    l.setFeatureClickColumns(["cartodb_id"])
    l.on("featureClicked", (e) => {
      setClickedPointData(e)
    })
    state.cartoClient.addLayer(l)
    return l
  }, [state.mapSource])

  const leafletLayer = useMemo(() => {
    if (!layer || !mapRef.current) {
      leafletLayer && mapRef.current.removeLayer(leafletLayer)
      return
    }
    let l = state.cartoClient.getLeafletLayer()
    l.addTo(mapRef.current)
    l.setZIndex(200)
    return l
  }, [mapRef.current, layer])

  useEffect(() => {
    if (!mapRef.current) {
      return
    }
    let m = mapRef.current
    m.on("moveend", (e) => {
      setMapOptions({
        center: [m.getCenter().lat, m.getCenter().lng],
        zoom: m.getZoom(),
      })
    })
      .on("click", (e) => {
        //        showPopupForLocation(e.latlng, m)
        setClickedMapData({ latlng: e.latlng })
      })
      .on("mouseup", (e) => {})

    L.easyButton("<i class='fas fa-camera'></i>", onMapImage, {
      position: "topleft",
    }).addTo(mapRef.current)

    L.control
      .layers(baseLayers, null, { position: "topleft" })
      .addTo(mapRef.current)

    mapRef.current.addLayer(baseLayers["地理院タイル(淡色)"])
  }, [mapRef.current])

  useEffect(() => {
    if (!mapRef.current || !cameraButton) {
      return
    }
    cameraButton.addTo(mapRef.current)
  }, [mapRef.current, cameraButton])

  useEffect(() => {
    if (!state.focusedPoint) {
      return
    }

    const m = L.marker(state.focusedPoint)
    m.addTo(mapRef.current)
    setFocusedMarker(m)
    mapRef.current.setView(state.focusedPoint, 18, {
      animate: true,
      duration: 3.0,
    })

    return () => {
      if (focusedMarker) {
        mapRef.current.removeLayer(focusedMarker)
      }
    }
  }, [mapRef.current, state.focusedPoint])

  useEffect(() => {
    if (
      !mapRef.current ||
      !state.selected ||
      !state.rows ||
      state.selected.length === 0 ||
      state.rows.length === 0
    ) {
      return
    }

    let latlngs = state.rows
      .filter((r) => {
        return state.selected.includes(r.cartodb_id)
      })
      .map((r) => {
        let buffer = new Buffer(r.the_geom, "hex")
        let geom = wkx.Geometry.parse(buffer)
        return [geom.y, geom.x]
      })
    if (!latlngs || latlngs.length === 0) {
      return
    }
    if (latlngs.length === 1) {
      mapRef.current.panTo(latlngs[0])
    } else {
      mapRef.current.fitBounds(L.latLngBounds(latlngs), {
        padding: [20, 20],
        maxZoom: 15,
      })
    }
  }, [mapRef.current, state.selected, state.rows])

  useEffect(() => {
    if (!mapRef.current || !selectedLayer) {
      return
    }

    let nLayer = layers[selectedLayer]
    nLayer.setZIndex(100)
    nLayer.addTo(mapRef.current)

    setLegend(layerConfig[selectedLayer]?.legend)

    let attribute = layerConfig[selectedLayer]?.attribute
    if (attribute) {
      setSourceTitle(attribute?.title)
      setSourceLink(attribute?.url)
      setSourceSubTitle(attribute?.sub_title)
    }

    return () => {
      mapRef.current.removeLayer(nLayer)
      setLegend(null)
    }
  }, [mapRef.current, selectedLayer])

  useEffect(() => {
    if (infoWindowTimer) {
      clearTimeout(infoWindowTimer)
    }

    infoWindowTimer = setTimeout(() => {
      let data = clickedPointData ?? clickedMapData
      if (!data || !mapRef.current) {
        return
      }
      let latlng = data.latlng ?? L.latLng(data.latLng.lat, data.latLng.lng)

      let ll = [latlng.lat, latlng.lng]
      L.popup()
        .setLatLng(ll)
        .setContent("<span>読込中...</span>")
        .openOn(mapRef.current)

      if (data.data?.cartodb_id) {
        showPopupForCartodbId(
          data.data.cartodb_id,
          latlng,
          mapRef.current
        ).then(() => {})
      } else {
        showPopupForLocation(data.latlng, mapRef.current).then(() => {})
      }
      setClickedPointData(null)
      setClickedMapData(null)
    }, 500)
  }, [clickedMapData, clickedPointData])

  L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling)
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  })
  const mapStyles = {
    width: "100vw",
    height: "840px",
    margin: "0",
  }

  const onMapImage = () => {
    if (!mapRef.current) {
      return
    }
    props.onExport()
  }

  const showPopupForCartodbId = async (cartodbId, latlng, _map) => {
    let query = `SELECT * FROM "${process.env.REACT_APP_CARTO_USERNAME}".v_sekoujisseki WHERE cartodb_id = ${cartodbId}`

    let res = await First(query).catch((e) => {
      console.log("[Query]", "error", e)
    })

    if (!res) {
      return
    }

    let j = Get("search_actual_current_column_state")
    if (!j) {
      return
    }
    let columnState = JSON.parse(j)

    let tr = []

    for (const col of columnState) {
      let cid = col.colId
      let name = columnHeaderNames[cid]
      if (col.hide || !name) {
        continue
      }
      console.log(cid, res[cid], columnHeaderNames[cid])
      if (cid === "photo" && res[cid]) {
        tr.push(
          `<tr><th nowrap>${name}</th><td><a href="${res[cid]}" target="_blank">画像を開く(別タブ)</a></td></tr>`
        )
      } else {
        tr.push(`<tr><th nowrap>${name}</th><td>${res[cid] ?? "--"}</td></tr>`)
      }
    }

    L.popup()
      .setLatLng(latlng)
      .setContent(`<table style="overflow: auto">${tr.join("")}</table>`)
      .openOn(_map)
  }

  const showPopupForLocation = async (latlng, _map) => {
    let ll = [latlng.lat, latlng.lng]

    // mesh3の取得
    let [address, yoto, dosyasaigai, tsunami, rain, pml] = await Promise.all([
      _calcAddress(ll),
      _calcYoto(ll),
      _calcDosyasaigai(ll),
      _calcTsunami(ll),
      _calcRain(ll),
      _calcPml(ll),
    ])

    L.popup()
      .setLatLng(latlng)
      .setContent(
        `
        <table>
        <tr><th nowrap>住所</th><td>${address ?? "--"}</td></tr>
        <tr><th nowrap>用途</th><td>${yoto?.yoto ?? "--"}</td></tr>
        <tr><th nowrap>建蔽率</th><td>${yoto?.kenpe_rate ?? "--"}</td></tr>
        <tr><th nowrap>容積率</th><td>${yoto?.yoseki_rate ?? "--"}</td></tr>
        <tr><th nowrap>土砂災害警戒区域</th><td>${dosyasaigai ?? "--"}</td></tr>
        <tr><th nowrap>津波浸水想定区域</th><td>${tsunami ?? "--"}</td></tr>
        <tr><th nowrap>年間合計雨量</th><td>${rain ?? "--"}</td></tr>
        <tr><th nowrap>PML(RC造新耐震)</th><td>${pml?.rc_shin ?? "--"}</td></tr>
        <tr><th nowrap>PML(RC造旧耐震)</th><td>${pml?.rc_kyu ?? "--"}</td></tr>
        <tr><th nowrap>PML(S造新耐震)</th><td>${pml?.s_shin ?? "--"}</td></tr>
        <tr><th nowrap>PML(S造旧耐震)</th><td>${pml?.s_kyu ?? "--"}</td></tr>
        <tr><th nowrap>地表面最大速度</th><td>${
          pml?.surface_velocity ?? "--"
        }</td></tr>
        </table>
        `
      )
      .openOn(_map)
  }

  const _calcCentroid = async (geom) => {
    let res = await SyncARow(`
      SELECT ST_X(center) AS longitude, ST_Y(center) AS latitude
      FROM (
             SELECT ST_Centroid(ST_GeomFromGeoJSON('${JSON.stringify(
               geom
             )}')) AS center
           ) AS foo
      `)
    return [res["latitude"], res["longitude"]]
  }

  const _calcMesh3 = async (latlng) => {
    let res = await SyncARow(`
      SELECT meshcode FROM mesh_3 WHERE ST_Contains(the_geom,ST_SetSRID(ST_MakePoint( ${latlng[1]}, ${latlng[0]}), 4326))    
    `)

    return res?.meshcode
  }

  const _calcAddress = async (latlng) => {
    let res = await Address(latlng[0], latlng[1])
    let data = res.data?.result
    let addr = []
    if (data) {
      addr.push(data.prefecture?.pname?.trim() ?? "")
      addr.push(data.municipality?.mname?.trim() ?? "")
      if (data.local && data.local.length > 0) {
        addr.push(data.local[0].section?.trim() ?? "")
      }
    }
    return addr.join("") + "付近"
  }

  const _calcYoto = (latlng) => {
    return new Promise((resolve, reject) => {
      First(`
      SELECT 
             youto_name as yoto,
             kenpei as kenpe_rate,
             youseki as yoseki_rate
      FROM "${process.env.REACT_APP_CARTO_USERNAME}".youto2019
      WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
      LIMIT 1
      `)
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  const _calcDosyasaigai = (latlng) => {
    return new Promise((resolve, reject) => {
      One(`
      SELECT a33_001_data || ' (' || a33_002_data || ')'
      FROM "${process.env.REACT_APP_CARTO_USERNAME}".dosyasaigaikeikaikuiki
      WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
      LIMIT 1
      `)
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
  const _calcTsunami = (latlng) => {
    return new Promise((resolve, reject) => {
      One(`
      SELECT inundation_depth_rank AS name
      FROM "${process.env.REACT_APP_CARTO_USERNAME}".tsunami_inundation
      WHERE
        ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
      LIMIT 1
      `)
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  const _calcRain = (latlng) => {
    return new Promise((resolve, reject) => {
      One(`
      SELECT g02_014 / 10 AS rain FROM rain WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
      `)
        .then((ret) => {
          resolve(ret.toFixed(1))
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  const _calcPml = async (latlng) => {
    return new Promise((resolve, reject) => {
      First(`
      SELECT 
      amplification_factor,
      surface_velocity_475y AS surface_velocity,
      pml_rc_kyutaishin_is0_4 AS rc_kyu,
      pml_rc_shintaishin_is0_6 AS rc_shin,
      pml_s_kyutaishin_is0_4 AS s_kyu,
      pml_s_shintaishin_is0_6 AS s_shin
    FROM "${process.env.REACT_APP_CARTO_USERNAME}".pml
    WHERE ST_Contains(the_geom, ST_SetSRID(ST_MakePoint(${latlng[1]}, ${latlng[0]}),4326))
      `)
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  const onClickLayer = (e) => {
    setSelectedLayer(null)
    setSelectedLayer(e.target.value)
  }

  return (
    <Box className={classes.root} ref={rootRef}>
      <MapContainer
        style={mapStyles}
        whenCreated={(m) => {
          mapRef.current = m
        }}
        {...state.mapOptions}
      />
      <Snackbar
        open={generatingImage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        message="画像を作成し地ます"
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          画像を生成します
        </Alert>
      </Snackbar>
      <Box className={classes.layerControl}>
        {layers && (
          <Box className={classes.layers}>
            <div>
              <label>
                <input
                  type="radio"
                  name="layers"
                  checked={!selectedLayer && "checked"}
                  onClick={() => setSelectedLayer(null)}
                />
                なし
              </label>
            </div>
            {Object.keys(layers).map((key) => {
              return (
                <div key={key}>
                  <label>
                    <input
                      name="layers"
                      type="radio"
                      value={key}
                      checked={selectedLayer == key}
                      onClick={onClickLayer}
                    />
                    {key}
                  </label>
                </div>
              )
            })}
          </Box>
        )}
        {legend && (
          <Box className={classes.legend}>
            <table>
              {legend.map((l) => {
                return (
                  <tr key={l.label}>
                    <td
                      className={classes.legend_color}
                      style={{ backgroundColor: l.color }}
                    >
                      &nbsp;
                    </td>
                    <td className={classes.legend_label}>{l.label}</td>
                  </tr>
                )
              })}
            </table>
          </Box>
        )}
      </Box>
      {sourceTitle && (
        <Box className={classes.source}>
          {sourceLink && (
            <Typography variant="subtitle2" align="right">
              出典：<Link
                target="_blank"
                href="https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A31-v2_2.html"
              >
                {sourceTitle}
              </Link>
            </Typography>
          )}
          {!sourceLink && (
            <Typography variant="subtitle2" align="right">
              出典：{sourceTitle}
            </Typography>
          )}
          {sourceSubTitle && (
            <Typography variant="caption">{sourceSubTitle}</Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

SearchActualMap.propTypes = {
  onExport: PropTypes.func,
}

export default SearchActualMap
