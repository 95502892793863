import * as L from "leaflet"

// eslint-disable-next-line no-import-assign
L.drawLocal = {
  // format: {
  // 	numeric: {
  // 		delimiters: {
  // 			thousands: ',',
  // 			decimal: '.'
  // 		}
  // 	}
  // },
  draw: {
    toolbar: {
      // #TODO: this should be reorganized where actions are nested in actions
      // ex: actions.undo  or actions.cancel
      actions: {
        title: "中止",
        text: "中止する",
      },
      finish: {
        title: "完了",
        text: "完了する",
      },
      undo: {
        title: "最後のポイントを除去",
        text: "最後のポイントを除去する",
      },
      buttons: {
        polyline: "Draw a polyline",
        polygon: "区画の追加",
        rectangle: "Draw a rectangle",
        circle: "Draw a circle",
        marker: "Draw a marker",
        circlemarker: "Draw a circlemarker",
      },
    },
    handlers: {
      circle: {
        tooltip: {
          start: "Click and drag to draw circle.",
        },
        radius: "Radius",
      },
      circlemarker: {
        tooltip: {
          start: "Click map to place circle marker.",
        },
      },
      marker: {
        tooltip: {
          start: "Click map to place marker.",
        },
      },
      polygon: {
        tooltip: {
          start: "マップをクリックしてポイントを追加",
          cont: "さらに区画のポイントを追加",
          end: "クリックして区画を確定",
        },
      },
      polyline: {
        error: "<strong>Error:</strong> shape edges cannot cross!",
        tooltip: {
          start: "Click to start drawing line.",
          cont: "Click to continue drawing line.",
          end: "Click last point to finish line.",
        },
      },
      rectangle: {
        tooltip: {
          start: "Click and drag to draw rectangle.",
        },
      },
      simpleshape: {
        tooltip: {
          end: "Release mouse to finish drawing.",
        },
      },
    },
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: "確定",
          text: "確定",
        },
        cancel: {
          title: "変更をキャンセル",
          text: "変更をキャンセル",
        },
        clearAll: {
          title: "全クリア",
          text: "全クリア",
        },
      },
      buttons: {
        edit: "編集",
        editDisabled: "編集する区画がありません",
        remove: "区画を削除",
        removeDisabled: "削除する区画がありません",
      },
    },
    handlers: {
      edit: {
        tooltip: {
          text: "白四角をドラックで移動できます",
          subtext: "キャンセルでもとに戻せます",
        },
      },
      remove: {
        tooltip: {
          text: "クリックで削除できます",
        },
      },
    },
  },
}
