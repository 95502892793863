import axios from "axios"
import carto from "@carto/carto.js"

const client = new carto.Client({
  apiKey: process.env.REACT_APP_CARTO_API_KEY,
  username: process.env.REACT_APP_CARTO_USERNAME,
})

export const Query = (sql, params = {}) => {
  return new Promise((resolve, reject) => {
    try {
      let baseUrl = `${process.env.REACT_APP_CARTO_SERVER_URL}/${process.env.REACT_APP_CARTO_SQLAPI_PREFIX}`
      params["api_key"] = process.env.REACT_APP_CARTO_API_KEY

      let queryString = sql.replace(/\n/g, " ").replace(/ +/g, " ")
      params["q"] = encodeURI(queryString)

      let queryValues = []
      Object.keys(params).forEach((v, i) =>
        queryValues.push(`${v}=${params[v]}`)
      )
      let url = `${baseUrl}?${queryValues.join("&")}`
      axios.get(url).then((res) => {
        resolve(res)
      })
    } catch (e) {
      reject(e)
    }
  })
}

export const Rows = (sql, params = {}) => {
  return new Promise((resolve, reject) => {
    Query(sql, params)
      .then((ret) => {
        resolve(ret?.data?.rows)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const First = (sql, params = {}) => {
  return new Promise((resolve, reject) => {
    Rows(sql, params)
      .then((ret) => {
        resolve(ret?.shift())
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const One = (sql, params = {}, columnName = null) => {
  if (!params) {
    params = {}
  }
  return new Promise((resolve, reject) => {
    Query(sql, params)
      .then((ret) => {
        let colName = columnName ?? Object.keys(ret?.data?.fields ?? [])[0]
        let row = ret?.data?.rows?.shift()
        if (!row) {
          return resolve(null)
        }
        resolve(row[colName])
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const SyncARow = async (sql, params = {}) => {
  let res = await Query(sql, params)
  if (!res?.data?.rows) {
    return null
  }
  return res.data.rows[0]
}

export const Union = (layers) => {
  return new Promise((resolve, reject) => {
    let geoms = []
    let last = null
    for (const l of layers) {
      let g = l.toGeoJSON()
      if (!g.geometry) {
        continue
      }
      geoms.push(`ST_GeomFromGeoJSON('${JSON.stringify(g.geometry)}')`)
      last = g
    }
    if (geoms.length === 1) {
      return resolve(last)
    }
    let sql = `SELECT ST_AsGeoJSON(ST_Union(${geoms.join(",")})) AS u`

    Query(sql)
      .then((res) => {
        if (res.data?.rows) {
          let u = res.data.rows[0]["u"]
          resolve(JSON.parse(u))
          return
        }
        resolve(last)
      })
      .catch((e) => {
        console.error(e)
      })
  })
}

export const GetLeafletLayer = (cartoLayer) => {
  return client.getLeafletLayer(cartoLayer)
}

export const CreateLayer = (map, sql, css) => {
  let layer = new carto.layer.Layer(
    new carto.source.SQL(sql),
    new carto.style.CartoCSS(css)
  )
  client.addLayer(layer)
}
