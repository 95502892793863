import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"

if (!(process.env.REACT_APP_DEBUG_CONSOLE ?? true)) {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
