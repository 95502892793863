export default {
  name: "年間降水量",
  attribute: {
    title: "「国土数値情報（平年値メッシュデータ）」（気象庁）",
    url: "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-G02.html",
    sub_title: "※元データは0.1㎜単位で作成されているため、㎜単位に変換して使用した。",
  },
  query: "SELECT * FROM rain",
  css: `
#layer {
  polygon-fill:#FFFFFF;
  line-width: 1;
  line-color: #FFFFFF;
  line-opacity: 0.5;
  polygon-opacity: 0.5;
  [g02_014>=10000]{polygon-fill: #DDDDDD;}
  [g02_014>=15000]{polygon-fill: #00CCFF;}
  [g02_014>=20000]{polygon-fill: #0033FF;}
  [g02_014>=25000]{polygon-fill: #FFFF00;}
  [g02_014>=30000]{polygon-fill: #FFAA00;}
  [g02_014>=40000]{polygon-fill: #FF0000;}
 }
      `,
  legend: [
    { color: "#DDDDDD", label: "〜 1000" },
    { color: "#00CCFF", label: "1000 〜 2000" },
    { color: "#0033FF", label: "2000 〜 2500" },
    { color: "#FFFF00", label: "2500 〜 3000" },
    { color: "#FFAA00", label: "3000 〜 4000" },
    { color: "#FF0000", label: "4000 〜" },
  ],
}
