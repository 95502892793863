import CartodbLayer from "../../manager/cartodb/layer"
import * as L from "leaflet"

export const getLayerConfigs = (confNames, isLayer) => {
  let configs = {
    土砂災害警戒区域: require("./layers/doshasaigai").default,
    洪水浸水想定区域: require("./layers/kouzuisinsui").default,
    津波浸水想定区域: require("./layers/tsunami").default,
    竜巻発生地点: require("./layers/tatsumaki").default,
    年間降水量: require("./layers/rainfall").default,
    "PML（振幅率）": require("./layers/amplification_factor").default,
    "PML（RC造旧耐震）": require("./layers/pml_rc_kyutaishin").default,
    "PML（RC造新耐震）": require("./layers/pml_rc_shintaishin").default,
    "PML（S造旧耐震）": require("./layers/pml_s_kyutaishin").default,
    "PML（S造新耐震）": require("./layers/pml_s_shintaishin").default,
    地表面最大速度: require("./layers/surface_velocity").default,
  }

  let layers = null
  if (isLayer) {
    layers = {
      土砂災害警戒区域: new CartodbLayer(
        configs["土砂災害警戒区域"]
      ).leafletLayer(),
      洪水浸水想定区域: L.tileLayer(
        "https://disaportaldata.gsi.go.jp/raster/01_flood_l2_shinsuishin_data/{z}/{x}/{y}.png"
      ),
      津波浸水想定区域: new CartodbLayer(
        configs["津波浸水想定区域"]
      ).leafletLayer(),
      竜巻発生地点: new CartodbLayer(configs["竜巻発生地点"]).leafletLayer(),
      年間降水量: new CartodbLayer(configs["年間降水量"]).leafletLayer(),
      "PML（振幅率）": new CartodbLayer(
        configs["PML（振幅率）"]
      ).leafletLayer(),
      "PML（RC造旧耐震）": new CartodbLayer(
        configs["PML（RC造旧耐震）"]
      ).leafletLayer(),
      "PML（RC造新耐震）": new CartodbLayer(
        configs["PML（RC造新耐震）"]
      ).leafletLayer(),
      "PML（S造旧耐震）": new CartodbLayer(
        configs["PML（S造旧耐震）"]
      ).leafletLayer(),
      "PML（S造新耐震）": new CartodbLayer(
        configs["PML（S造新耐震）"]
      ).leafletLayer(),
      地表面最大速度: new CartodbLayer(
        configs["地表面最大速度"]
      ).leafletLayer(),
    }
  }

  return [configs, layers]
}
