import React from "react"
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material"
import HomeIcon from "@mui/icons-material/Home"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

const Header = (props) => {
  const { title } = props
  const history = useHistory()

  const toHome = () => {
    history.push("/")
  }

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          {!props.disableHomeButton && (<IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="home"
            sx={{ mr: 2 }}
            onClick={toHome}
          >
            <HomeIcon />
          </IconButton>)}
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h6" component="div">
            {title || "施工実績"}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  disableHomeButton: PropTypes.bool,
}

export default Header
