export const Set = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const Get = (key) => {
  let json = localStorage.getItem(key)
  if (!json) {
    return {}
  }
  return JSON.parse(json)
}

export const Keys = (prefix) => {
  return Object.keys(localStorage).filter((k) => {
    return prefix ? k.startsWith(prefix) : true
  })
}

export const List = (prefix) => {
  return Object.entries().filter(([k, _]) => {
    return prefix ? k.startsWith(prefix) : true
  })
}
