import React, { useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import carto from "@carto/carto.js"
import { DataContext } from "../index"
import "@carto/airship-style/dist/airship.css"

const SearchActualCompletionYearCategoryWidget = (props) => {
  const { state } = useContext(DataContext)

  const [categories] = useState([])
  const [dataView, setDataView] = useState(null)
  const title = "完了年度"
  const subTitle = "選択済みアイテムの完了年度"
  let widget = useRef()

  useEffect(() => {
    let dataView = new carto.dataview.Category(
      state.mapSource,
      "completion_year",
      {
        operation: carto.operation.COUNT,
      }
    )
    dataView.on("dataChanged", (newData) => {
      if (!widget || !widget.current) {
        return
      }
      widget.current.categories = newData.categories ?? []
    })
    dataView.on("error", (cartoError) => {
      if (!widget || !widget.current) {
        return
      }
    })
    /*
    dataView.on("operationChanged", (newData) => {
      //      console.log("[DataView]", "operation changed", newData)
    })
    dataView.on("statusChanged", (newData, error) => {
      //      console.log("[DataView]", "status changed", newData)
    })
     */
    setDataView(dataView)
    state.cartoClient.addDataview(dataView)
  }, [state.cartoClient, state.mapSource])

  useEffect(() => {
    console.log("Not implemented")
  }, [dataView])

  useEffect(() => {
    if (!widget) {
      return
    }
    widget.current.categories = categories
  }, [widget, categories])

  return (
    <as-category-widget
      style={{ backgroundColor: "transparent" }}
      ref={widget}
      visible-categories={10}
      heading={title}
      description={subTitle}
      disableInteractivity={true}
      disable-interactivity
    />
  )
}

SearchActualCompletionYearCategoryWidget.propTypes = {
  cartoClient: PropTypes.any,
}

export default SearchActualCompletionYearCategoryWidget
