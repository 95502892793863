import React, { useContext, useEffect, useState } from "react"
import { Box, List, ListItem, Typography } from "@mui/material"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { getLayerConfigs } from "../../../../../resouces/layerConfig"
import { DataContext } from "../../index"

const [layerConfig] = getLayerConfigs(null, false)

const useStyles = makeStyles({
  root: {
    width: "200px",
    padding: "20px",
    color: "white",
    backgroundColor: "#303030a8",
    borderRadius: "8px",
    border: "1px solid #999",
    boxShadow: "1px 1px 5px #9f9f9f91",
    fontSize: "9px",
    pointerEvents: "none",
  },
  list_item: {
    padding: 0,
  },
  legend_color: {
    width: "18px",
    height: "18px",
  },
})

const SearchPropertyOverviewMapLegend = (props) => {
  const classes = useStyles()
  const { state } = useContext(DataContext)
  const [legend, setLegend] = useState(null)

  useEffect(() => {
    if (!state.selectedLayer) {
      setLegend(null)
      return
    }

    setLegend(layerConfig[state.selectedLayer].legend)
  }, [state.selectedLayer])

  return (
    <Box className={classes.root + " " + props.className}>
      <Typography style={{ marginBottom: "8px" }}>
        {state.selectedLayer}
      </Typography>
      {legend && (
        <List>
          {legend.map((l) => {
            return (
              <ListItem key={l.label} style={{ padding: "1px 0" }}>
                <Box
                  className={classes.legend_color}
                  sx={{ backgroundColor: l.color, marginRight: "4px" }}
                />
                <Typography sx={{ flexGrow: 1, fontSize: "12px" }}>
                  {l.label}
                </Typography>
              </ListItem>
            )
          })}
        </List>
      )}
    </Box>
  )
}

SearchPropertyOverviewMapLegend.propTypes = {
  className: PropTypes.string,
  layerConfig: PropTypes.any,
}

export default SearchPropertyOverviewMapLegend
