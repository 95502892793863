import carto from "@carto/carto.js"

class CartodbLayer {
  cartoLayer

  client = new carto.Client({
    apiKey: process.env.REACT_APP_CARTO_API_KEY,
    username: process.env.REACT_APP_CARTO_USERNAME,
    serverUrl: process.env.REACT_APP_CARTO_SERVER_URL,
  })

  constructor(config) {
    this.cartoLayer = new carto.layer.Layer(
      new carto.source.SQL(config.query),
      new carto.style.CartoCSS(config.css)
    )
    this.client.addLayer(this.cartoLayer)
  }

  updateQuery = (query) => {
    this.cartoLayer.updateQuery(query)
  }

  leafletLayer = () => {
    return this.client.getLeafletLayer(this.cartoLayer)
  }
}

export default CartodbLayer
