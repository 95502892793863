export default {
  name: "土砂災害警戒区域",
  attribute: {
    title: "「国土数値情報（土砂災害警戒区域データ）」（国土交通省）",
    url: "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A33-v1_4.html",
  },
  query: "SELECT * FROM dosyasaigaikeikaikuiki",
  css: `
#layer {
  [a33_002=1]{polygon-fill:#FFFF00;}
  [a33_002=2]{polygon-fill:#FFAA00;}
  polygon-fill: #333333;
  polygon-opacity: 0.8;
  line-width: 0.5;
  line-dasharray:4,1;
  line-color: #333333;
  line-opacity: 0.5;
}
      `,
  legend: [
    { color: "#FFFF00", label: "土石流" },
    { color: "#FFAA00", label: "急傾斜地の崩壊" },
  ],
}
