import React, { useContext } from "react"
import { makeStyles } from "@mui/styles"
import { DataContext } from "../index"
import { Box, Button, TextField, Typography } from "@mui/material"
import Autocomplete from "react-google-autocomplete"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: "700px",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  input: {
    flexGrow: 1,
  },
  button: {
    width: "150px",
  },
})

const SearchPropertySearchAddress = (props) => {
  const { state, setPlace } = useContext(DataContext)
  const history = useHistory()
  const classes = useStyles()

  const onPlaceSelected = (place) => {
    setPlace(place)
  }

  const onSearchSimilar = () => {
    console.log(JSON.stringify(state.area_info))

    let params = {}
    if (state.area_info?.pml?.amplification_factor) {
      params["amplification_factor"] = state.area_info.pml.amplification_factor
    }
    if (state.area_info?.pml?.surface_velocity) {
      params["surface_velocity"] = state.area_info.pml.surface_velocity
    }
    if (state.area_info?.pml?.rc_kyu) {
      params["rc_kyu"] = state.area_info.pml.rc_kyu
    }
    if (state.area_info?.pml?.rc_shin) {
      params["rc_shin"] = state.area_info.pml.rc_shin
    }
    if (state.area_info?.pml?.s_kyu) {
      params["s_kyu"] = state.area_info.pml.s_kyu
    }
    if (state.area_info?.pml?.s_shin) {
      params["s_shin"] = state.area_info.pml.s_kyu
    }
    if (state.area_info?.rain) {
      params["rain"] = state.area_info.rain
    }

    window.open(
      "/search/actual?" +
        Object.keys(params)
          .map((key) => {
            return `${key}=${params[key]}`
          })
          .join("&")
    )
  }

  return (
    <Box className={classes.root}>
      <Autocomplete
        apiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY}
        className={classes.input}
        options={{ types: ["address"] }}
        aria-setsize="small"
        aria-label="住所・場所から検索"
        onPlaceSelected={onPlaceSelected}
      />
      <Box sx={1} />
      <Button
        disabled={!state.area_info}
        variant="contained"
        color="secondary"
        size="large"
        onClick={onSearchSimilar}
      >
        <Typography>同類の実績検索</Typography>
      </Button>
    </Box>
  )
}

export default SearchPropertySearchAddress
