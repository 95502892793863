const MapLayerStyle = `
#layer {
  marker-width: 14;
  marker-fill: #F33;
  marker-fill-opacity: 0.9;
  marker-line-color: #FFFFFF;
  marker-line-width: 1;
  marker-line-opacity: 0.7;
  marker-placement: point;
  marker-type: ellipse;
  marker-allow-overlap: true;
}`

export default MapLayerStyle
