import axios from "axios"

export const Address = (lat, lng) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://aginfo.cgk.affrc.go.jp/ws/rgeocode.php?json&lat=${lat}&lon=${lng}`
      )
      .then((res) => {
        resolve(res)
      })
  })
}
