import React, { useContext, useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/material"
import { DataContext } from "../../index"
import "dayjs"
import dayjs from "dayjs"
import "./index.css"

const useStyles = makeStyles({
  root: {
    display: "box",
  },
  table: {
    border: "0",
    margin: "4px",
  },
})

const SearchPropertyOverviewTable = (props) => {
  const classes = useStyles()
  const { state } = useContext(DataContext)

  const [transitIc, setTransitIc] = useState(null)
  const [transitTrain, setTransitTrain] = useState(null)

  useEffect(() => {
    if ((state.selectedTransitId?.length ?? 0) === 0) {
      setTransitIc(null)
      setTransitTrain(null)
      return
    }

    let i = []
    let t = []

    state.transitTrainData.forEach((v) => {
      if (state.selectedTransitId.includes(v.id)) {
        t.push(`${v.name}(${v.length}`)
      }
    })
    state.transitIcData.forEach((v) => {
      if (state.selectedTransitId.includes(v.id)) {
        i.push(`${v.name}(${v.length})`)
      }
    })
    setTransitIc(i.length > 0 ? [...i] : null)
    setTransitTrain(t.length > 0 ? [...t] : null)
  }, [state.selectedTransitId])

  useEffect(() => {
    console.log("[AreaInfo]", "update check!")
  }, [state.area_info])

  const getLengthText = (length) => {
    if (length > 11000) {
      return Math.floor(length / 1000) + "km"
    } else if (length > 1000) {
      return (length / 1000).toFixed(2) + "km"
    }
    return Math.round(length) + "m"
  }

  return (
    <Box className={classes.root}>
      <table
        id="overview_data_table"
        className={classes.table}
        border="0"
        cellPadding="0"
        cellSpacing="0"
      >
        <tr>
          <th>お客様名</th>
          <td>{state.customer ? state.customer + "様" : ""}</td>
        </tr>
        <tr>
          <th>作成日</th>
          <td>{dayjs().format("YYYY/MM/DD HH:mm:ss")}</td>
        </tr>
        <tr>
          <th>住所</th>
          <td>
            {state.area_info?.address ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>面積</th>
          <td>
            {state.area_info?.area ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>用途地域</th>
          <td>
            {state.area_info?.yoto?.map((e) => <div key={e}>{e}</div>) ??
              (state.area_info?.is_loading ? "..." : "--")}{" "}
          </td>
        </tr>
        <tr>
          <th>建蔽率</th>
          <td>
            {state.area_info?.kenpe?.map((e) => <div key={e}>{e}</div>) ??
              (state.area_info?.is_loading ? "..." : "--")}{" "}
          </td>
        </tr>
        <tr>
          <th>容積率</th>
          <td>
            {state.area_info?.yoseki?.map((e) => <div key={e}>{e}</div>) ??
              (state.area_info?.is_loading ? "..." : "--")}{" "}
          </td>
        </tr>
        {transitIc && (
          <tr>
            <th>最寄り(IC)</th>
            <td>
              {transitIc.map((t) => (
                <div key={t}>{t}</div>
              ))}
            </td>
          </tr>
        )}
        {transitTrain && (
          <tr>
            <th>最寄り(電車)</th>
            <td>
              {transitTrain.map((t) => (
                <div key={t}>{t}</div>
              ))}
            </td>
          </tr>
        )}
        <tr>
          <th>土砂災害警戒区域</th>
          <td>
            {(state.area_info?.dosyasaigai?.length ?? 0) > 0
              ? state.area_info?.dosyasaigai.map((v) => {
                  return <div key={v}>{v}</div>
                })
              : state.area_info?.is_loading
              ? "..."
              : "--"}
          </td>
        </tr>
        <tr>
          <th>津波最大浸水想定</th>
          <td>
            {state.area_info?.tsunami ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>洪水浸水想定区域</th>
          <td>
            {state.area_info?.kouzui ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>竜巻発生地点</th>
          <td>
            {state.area_info?.tatsumaki ??
                (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>年間合計雨量</th>
          <td>
            {state.area_info?.rain ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>PML(RC造新耐震)</th>
          <td>
            {state.area_info?.pml?.rc_shin ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>PML(RC造旧耐震)</th>
          <td>
            {state.area_info?.pml?.rc_kyu ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>PML(S造新耐震)</th>
          <td>
            {state.area_info?.pml?.s_shin ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>PML(S造旧耐震)</th>
          <td>
            {state.area_info?.pml?.s_kyu ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
        <tr>
          <th>地表面最大速度</th>
          <td>
            {state.area_info?.pml?.surface_velocity ??
              (state.area_info?.is_loading ? "..." : "--")}
          </td>
        </tr>
      </table>
    </Box>
  )
}

export default SearchPropertyOverviewTable
