import PropTypes from "prop-types"
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import { makeStyles } from "@mui/styles"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { DataContext } from "../index"
import "ag-grid-enterprise"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"
import LOCALE_JA from "../../../../resouces/aggrid/locale.ja"
import { Query, Rows, One } from "../../../../manager/carto"
import {
  filterModelToWhereStringArray,
  numberDateFilterModelToWhereString,
} from "../../../../manager/agGrid"
import { Get, Set } from "../../../../manager/storage"
import { Button, Typography } from "@mui/material"
import ExcelJS from "exceljs"
import { useLocation } from "react-router-dom"
import { LicenseManager } from "ag-grid-enterprise"
import columnDef from "./columnDef"
import columnStatePresetKenchiku from "./columnStatePreset/kenchiku"
import columnStatePresetDoboku from "./columnStatePreset/doboku"
import columnStatePresetFudosan from "./columnStatePreset/fudosan"

const useStyles = makeStyles({
  root: {
    width: "calc(100% - 40px)",
    height: "50vh",
    margin: "0 auto",
  },
})

LicenseManager.setLicenseKey(
  "CompanyName=4amjp,LicensedApplication=Sekou,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026247,ExpiryDate=11_March_2023_[v2]_MTY3ODQ5MjgwMDAwMA==d00a0b638f0ef1005c9a6c2c21d3c6d0"
)

const SearchActualTable = (props, ref) => {
  const classes = useStyles()

  const search = useLocation().search
  const { state, setSelected, startLoading, stopLoading, setFilterModel } =
    useContext(DataContext)

  const [gridApi, setGridApi] = useState(null)
  const [columnApi, setColumnApi] = useState(null)
  const [hideColumns, setHideColumns] = useState({})

  useEffect(() => {
    setHideColumns(Get("search_actual_list_column_saved")?.hides ?? {})
  }, [])

  useEffect(() => {
    if (!gridApi) {
      return
    }
    gridApi.paginationGoToFirstPage()

    let queryParams = new URLSearchParams(search)
    //console.log('[Actual]', 'search', search, queryParams)
    let model = {}

    queryParams.forEach((value, key, f) => {
      console.log(key, value)
      switch (key) {
        case "rc_shin":
          model["pml_rc_shintaishin_is0_6"] = {
            filterType: "number",
            type: "greaterThanOrEqual",
            filter: parseFloat(value),
          }
          break
        case "rc_kyu":
          model["pml_rc_kyutaishin_is0_4"] = {
            filterType: "number",
            type: "greaterThanOrEqual",
            filter: parseFloat(value),
          }
          break
        case "s_kyu":
          model["pml_s_kyutaishin_is0_4"] = {
            filterType: "number",
            type: "greaterThanOrEqual",
            filter: parseFloat(value),
          }
          break
        case "s_shin":
          model["pml_s_shintaishin_is0_6"] = {
            filterType: "number",
            type: "greaterThanOrEqual",
            filter: parseFloat(value),
          }
          break
        case "rain":
          model["rain"] = {
            filterType: "number",
            type: "greaterThanOrEqual",
            filter: parseInt(value) / 10,
          }
          break
        case "surface_velocity":
          model["chihyo_sokudo"] = {
            filterType: "number",
            type: "greaterThanOrEqual",
            filter: parseFloat(value),
          }
          break
      }
    })
    setFilterModel(model)
  }, [gridApi])

  useEffect(() => {
    if (!columnApi) {
      return
    }
    /*          <ToggleButton value="kenchiku">建築</ToggleButton>
          <ToggleButton value="doboku">土木</ToggleButton>
          <ToggleButton value="fudosan">不動産</ToggleButton>

     */
    if (state.dataType.includes("kenchiku")) {
      console.log("Kenchiku")
      columnApi.setColumnState(columnStatePresetKenchiku)
    } else if (state.dataType.includes("doboku")) {
      console.log("doboku")
      columnApi.setColumnState(columnStatePresetDoboku)
    } else if (state.dataType.includes("fudosan")) {
      console.log("fudosan")
      columnApi.setColumnState(columnStatePresetFudosan)
    }
  }, [columnApi, state.dataType])

  useEffect(() => {
    if (!gridApi) {
      return
    }
    // データ更新はdataSourceを与えた時点でプロパティ化されているのでstate含め内容更新する場合には
    // datasourceを再割り当てする必要がある
    console.log("[DataType]", "reload")
    gridApi.setServerSideDatasource(dataSource)
  }, [state.viewMode, state.filterWords, state.dataType])

  useEffect(() => {
    if (!gridApi) {
      return
    }
  }, [gridApi, state.selected])

  useImperativeHandle(ref, () => ({
    exportExcel,
  }))

  const onGridReady = (params) => {
    console.log("[AgGrid]", "onGridReady")
    setGridApi(params.api)
    setColumnApi(params.columnApi)

    params.api.setServerSideDatasource(dataSource)
  }

  const onFirstDataRendered = (event) => {
    //    console.log("[onFirstDataRendered]", event, state.selected)
  }

  const onColumnVisible = (event) => {
    Set(
      "search_actual_current_column_state",
      JSON.stringify(columnApi.getColumnState())
    )

    if (event.source === "api") {
      return
    }

    if (event.type === "columnVisible" && event.source === "toolPanelUi") {
      let hCols = hideColumns
      event.columns.forEach((c) => {
        hCols[c.colId] = !event.visible
      })
      setHideColumns({ ...hCols })
      Set("search_actual_list_column_saved", {
        ...Get("search_actual_list_column_saved"),
        hides: hCols,
      })
    }
  }

  const onRowDataChanged = () => {
    if (!gridApi) {
      return
    }
    gridApi.forEachNode((node) => {
      //      console.log(node)
    })
  }

  const onRowSelected = (event) => {
    //    console.log("[onRowSelected]", event)
  }

  const onSelectionChange = (event) => {
    let selected = state.selected.concat()
    gridApi.getRenderedNodes().forEach((node) => {
      if (node.selected && !selected.includes(node.data.cartodb_id)) {
        selected.push(node.data.cartodb_id)
      } else if (!node.selected && selected.includes(node.data.cartodb_id)) {
        selected.splice(selected.indexOf(node.data.cartodb_id), 1)
      }
    })
    setSelected(selected)
  }

  const onPaginationChanged = (event) => {
    if (!gridApi) {
      return
    }
    for (const node of gridApi.getRenderedNodes()) {
      node.setSelected(state.selected.includes(node.data?.cartodb_id ?? 0))
    }
  }

  const onFilterChanged = (event) => {
    setFilterModel(gridApi.getFilterModel())
  }

  useEffect(() => {
    if (!gridApi) {
      return
    }

    gridApi.setFilterModel(state.filterModel)
  }, [gridApi, state.filterModel])

  const exportExcel = () => {
    gridApi.exportDataAsExcel({
      onlySelectedAllPages: true,
    })
  }

  const getData = (params) => {
    return new Promise((resolve, reject) => {
      startLoading(async () => {
        let val = {}

        // フィルターの生成
        let wheres = Object.keys(params.request.filterModel).flatMap((name) => {
          if (
            name === "start_construction_period" ||
            name === "end_construction_period"
          ) {
            let v = numberDateFilterModelToWhereString(
              name,
              params.request.filterModel[name]
            )
            if (v) {
              return [v]
            } else {
              return []
            }
          } else {
            return filterModelToWhereStringArray(
              name,
              params.request.filterModel[name]
            )
          }
        })

        // viewmodeが選択のみだった場合
        if (state.viewMode === "select") {
          if (state.selected.length === 0) {
            wheres.push("FALSE") // 何も出さない
          } else {
            wheres.push(`cartodb_id IN (${state.selected.join(",")})`)
          }
        }

        // wordがあれば
        if ((state.filterWords?.length ?? 0) > 0) {
          let words = []
          words.push(`name LIKE '%${state.filterWords}%'`)
          words.push(`location LIKE '%${state.filterWords}%'`)
          words.push(`original_client LIKE '%${state.filterWords}%'`)
          words.push(`client LIKE '%${state.filterWords}%'`)
          words.push(`_order LIKE '%${state.filterWords}%'`)
          words.push(`design LIKE '%${state.filterWords}%'`)
          words.push(
            `use_major_classification_name_1 LIKE '%${state.filterWords}%'`
          )
          words.push(
            `use_minor_classification_name_1 LIKE '%${state.filterWords}%'`
          )
          words.push(`charasteric_1 LIKE '%${state.filterWords}%'`)
          words.push(
            `use_major_classification_name_2 LIKE '%${state.filterWords}%'`
          )
          words.push(
            `use_minor_classification_name_2 LIKE '%${state.filterWords}%'`
          )
          words.push(`building_name LIKE '%${state.filterWords}%'`)
          wheres.push("(" + words.join(" OR ") + ")")
        }

        wheres.push("the_geom IS NOT NULL")
        console.log("[検索]", wheres)

        // ソートの生成
        let orderBy = params.request.sortModel.map((v) => {
          return `${v.colId} ${v.sort}`
        })
        if (orderBy.length === 0) {
          orderBy = ["cartodb_id"]
        }
        console.log("sort", orderBy)

        let sql = `
      SELECT * FROM "${process.env.REACT_APP_CARTO_USERNAME}".v_sekoujisseki
      WHERE ${wheres.join(" AND ")}
      ORDER BY ${orderBy.join(", ")}
      LIMIT ${params.request.endRow - (params.request.startRow ?? 0)} 
          OFFSET ${params.request.startRow ?? 0}
      `
        console.log("[DataSource]", "query", sql)

        val.count = await One(
          `SELECT COUNT(*) FROM "${
            process.env.REACT_APP_CARTO_USERNAME
          }".v_sekoujisseki WHERE ${wheres.join(" AND ")}`
        )
        val.data = await Rows(sql)

        stopLoading()

        resolve(val)
      })
    })
  }

  const dataSource = {
    getRows(params) {
      console.log("[ServerSide]", "request", params.request)
      getData(params).then((ret) => {
        console.log("[ServerSide]", "get data result", ret)
        params.success({
          rowData: ret.data,
          rowCount: ret.count,
          storeInf: ret.info,
        })
      })
    },
  }

  return (
    <div className={`${classes.root} ag-theme-balham`}>
      <AgGridReact
        rowModelType="serverSide"
        localeText={LOCALE_JA}
        overlayLoadingTemplate={
          '<span className="ag-overlay-loading-center">読込中...</span>'
        }
        serverSideStoreType="partial"
        pagination={true}
        paginationAutoPageSize={true}
        rowSelection="multiple"
        onFilterChanged={onFilterChanged}
        onPaginationChanged={onPaginationChanged}
        onSelectionChanged={onSelectionChange}
        onRowDataChanged={onRowDataChanged}
        onFirstDataRendered={onFirstDataRendered}
        onColumnVisible={onColumnVisible}
        onRowSelected={onRowSelected}
        enableRangeSelection={true}
        rowHeight={30}
        headerHeight={20}
        suppressRowClickSelection={true}
        floatingFiltersHeight={20}
        columnDefs={columnDef}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
        }}
        sideBar={{
          toolPanels: [
            {
              id: "columns",
              labelDefault: "列選択",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: "columns",
        }}
        onGridReady={onGridReady}
      />
    </div>
  )
}

SearchActualTable.propTypes = {
  viweMode: PropTypes.string,
}

export default forwardRef(SearchActualTable)
