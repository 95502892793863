export default {
  name: "地表面最大速度",
  attribute: {
    title: "「地震リスク評価技術」（株式会社フジタ）",
  },
  query: `
SELECT
    cartodb_id,
    the_geom_webmercator,
    surface_velocity_475y AS value
FROM "${process.env.REACT_APP_CARTO_USERNAME}".pml
`,
  css: `
#layer [value<25] {
  polygon-fill: #9fe187;
  polygon-opacity: 0.75;
}
#layer [value>=25][value<50] {
  polygon-fill: #d2e187;
  polygon-opacity: 0.75;
}
#layer [value>=50][value<75] {
  polygon-fill: #e1cc87;
  polygon-opacity: 0.75;
}
#layer [value>=75][value<100] {
  polygon-fill: #e1aa87;
  polygon-opacity: 0.75;
}
#layer [value>=100][value<125] {
  polygon-fill: #e19987;
  polygon-opacity: 0.75;
}
#layer [value>=125][value<150] {
  polygon-fill: #e18787;
  polygon-opacity: 0.75;
}
#layer [value>=150] {
  polygon-fill: #434141;
  polygon-opacity: 0.75;
}


#layer::outline {
  line-width: 1;
  line-color: #FFFFFF;
  line-opacity: 0.5;
}
      `,
  legend: [
    { color: "#9fe187", label: "〜 25" },
    { color: "#d2e187", label: "25 〜 50" },
    { color: "#e1cc87", label: "50 〜 75" },
    { color: "#e1aa87", label: "75 〜 100" },
    { color: "#e19987", label: "100 〜 125" },
    { color: "#e18787", label: "125 〜 150" },
    { color: "#434141", label: "150 〜" },
  ],
}
