import React from "react"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: {
    backgroundColor: "blue",
  },
})

const Search = (props) => {
  const classes = useStyles()

  return <div className={classes.root}>Fuga</div>
}

export default Search
