import initialState from './state'

export const ActionType = {

  SetInitialized: "SET_INITIALIZED",

  FetchStarted: "FETCH_STARTED",
  FetchCompleted: "FETCH_COMPLETED",

  Clear: "CLEAR",

  SetCustomer: "SET_CUSTOMER",
  SetPlace: "SET_PLACE",
  SetArea: "SET_AERA",
  SetAreaInfo: "SET_AREA_INFO",

  SetTransitTrainData: "SET_TRANSIT_TRAIN_DATA",
  SetTransitIcData: "SET_TRANSIT_IC_DATA",
  SetSelectedTransitId: "SET_SELECTED_TRANSIT_ID",

  SetMapPoint: "SET_MAP_POINT",
  SetMapPointInfo: "SET_MAP_POINT_INFO",

  SetDataAddress: "SET_DATA_ADDRESS",

  SetSelectedLayer: "SET_SELECTED_LAYER",
}

const SearchPropertyDataReducer = (state, action) => {
  switch (action.type) {
    case ActionType.SetInitialized:
      return { ...state, initialized: action.value}
    case ActionType.FetchStarted:
      return { ...state, isLoading: true, error: null }
    case ActionType.FetchCompleted:
      return { ...state, isLoading: false, error: action.error }
    case ActionType.Clear:
      return { ...initialState, ...(action.value ?? {})}
    case ActionType.SetCustomer:
      return { ...state, customer: action.value }
    case ActionType.SetPlace:
      return { ...state, place: action.value }
    case ActionType.SetTransitTrainData:
      return { ...state, transitTrainData: action.value }
    case ActionType.SetTransitIcData:
      return { ...state, transitIcData: action.value }
    case ActionType.SetSelectedTransitId:
      return { ...state, selectedTransitId: action.value }
    case ActionType.SetArea:
      return { ...state, area: action.value, area_info: { is_loading: true } }
    case ActionType.SetAreaInfo:
      return { ...state, area_info: action.value }
    case ActionType.SetMapPoint:
      return { ...state, map_point: action.value, map_point_info: null }
    case ActionType.SetMapPointInfo:
      return { ...state, map_point_info: action.value }
    case ActionType.SetSelectedLayer:
      return {...state, selectedLayer: action.value}
    default:
      break
  }
  return state
}

export default SearchPropertyDataReducer
