export default [
  {
    colId: "0",
    hide: false,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "client",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "completion_year",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "location",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "structure",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "design",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "jv_ratio",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "amount_sold",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "construction_floor_area",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "name",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "number_ground_floors",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "site_area",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_equipment_name_1",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_method_1",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_major_classification_name_1",
    width: 200,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_minor_classification_name_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "_order",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "amount_order",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "branch",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "building_name",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "charasteric_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "charasteric_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "charasteric_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "director",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "end_construction_period",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "height",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "jv_or_independent",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "legal_floor_area",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "major_name_100",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "major_name_200",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "major_name_300",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "minor_name_100",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "minor_name_200",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "minor_name_300",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "number_basements",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "number_beds",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "number_buildings",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "number_rooms",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "number_seats",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "order_year",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "original_client",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "performance_1_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "performance_1_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "performance_1_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "performance_2_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "performance_2_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "performance_2_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "pile_length",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "pile_type",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "public_or_private",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "registration_range_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "registration_range_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "registration_range_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "sales_staff",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_equipment_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_equipment_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_equipment_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_equipment_name_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_equipment_name_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_equipment_performance_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_equipment_performance_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_equipment_performance_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_method_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "special_method_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "start_construction_period",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_major_classification_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_major_classification_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_major_classification_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_major_classification_name_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_major_classification_name_3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_minor_classification_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_minor_classification_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "use_minor_classification_name_2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "client_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "client_cd",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "completion_year_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Contract_Price",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "jv_ratio_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "location_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "design_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kouji_CD",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kouji_Kenmei",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "shiten_nm",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "adviser_fuku",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "adviser_main",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "amount_whole_wo_tax",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "construction_condition",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "construction_method",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "construction_scale",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "construction_type",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "end_location_nm",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Environmental_measures",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kouji_NM",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "main_construction_std",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "official_jv_nm",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "safety_measures",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "summary",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "technical_special_note",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "amount_w_tax",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "amount_whole_w_tax",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "contract_month",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "contract_year",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "corins_no",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "corins_no1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "corins_no2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "employee_number",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "end_construction_day",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "end_construction_month",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "end_construction_year",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "end_engagement",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "end_location_cd",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "external_award",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "jv_category",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "jv_cd",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "jv_detail",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kouji_CD_Serial",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "kouji_edaban",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "kouji_kanri_kikan_nm",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kouji_Kouki_ED_DT",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kouji_Kouki_ST_DT",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "kouji_nm_kana",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kouji_Status",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "owner_nm",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "PROJCD",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Shiten_CD",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "site_agent_cd",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "site_agent_end",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "site_agent_nm",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "site_agent_start",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "start_construction_day",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "start_construction_month",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "start_construction_year",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "start_engagement",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "start_location_cd",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "syscode",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "taiyohin",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Url",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Shiten_NM",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "structure_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Area_measure",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Area_Public",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Busyo_NM",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Chiban",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Comment_Inner",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Form_Torihiki",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kenpei_Rate1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Other_Address",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "PRE_DIV",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Price",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Rank",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Shikutyouson",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Situation_Anken",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Situation_Kentou",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Tanechi_Minimum_Flg",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Tantou_NM",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Todouhuken_NM",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Youseki_Rate1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Zoning1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Build_Month",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Build_Year",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Chimoku",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Chimoku_Multi",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "DM_FLG",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Estate_CD",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kaisou",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kaituke_Remarks",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kaituke_Situation",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kenpei_Rate2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kenpei_Rate3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Kuiki_Div",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Legal_Remarks",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Limit_FireProtection",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Limit_Height",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Limit_Other",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Limit_Shade",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "MapFile_Detail",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "MapFile_Wide",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Nyusatu_DT",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Nyusatu_Method",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Other",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Price_Range",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Price_Remarks",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Shidou_Hutan",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Shiten_CD_1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Situation_Anken_Remarks",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Source_Busyo",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Source_Company",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Source_Tantou",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Contact1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Contact2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Contact3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Contact4",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Direct1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Direct2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Direct3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Direct4",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Div1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Div2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Div3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Div4",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Width1",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Width2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Width3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Surface_Road_Width4",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Syuushi_Kentou_Remarks",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Syuushi_Kentou_Situation",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Tantou_CD",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Tatemono_Remarks",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Tatemono_Youto",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Todouhuken_CD",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Total_Area",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Traffic",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Youseki_Rate2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Youseki_Rate3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Zinushi",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Zinushi_Mendan_Remarks",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Zinushi_Mendan_Situation",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Zoning2",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "Zoning3",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "chihyo_sokudo",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "dosya",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "kouzui",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "photo",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "pml_rc_kyutaishin_is0_4",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "pml_rc_shintaishin_is0_6",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "pml_s_kyutaishin_is0_4",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "pml_s_shintaishin_is0_6",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "rain",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "tatsumaki",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "tsunami",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "npsscore_continuous_order",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
  {
    colId: "npsscore_recommendation",
    width: 200,
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
  },
]
