import { useReducer } from "react"
import reducer, { ActionType } from "./reducer"
import initialState from "./state"

const UseRootData = () => {
  const [rootState, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  const setAdData = (value) => {
    dispatch({ type: ActionType.SetAdData, value: value })
  }

  return {
    rootState,
    setAdData,
  }
}

export default UseRootData
