import React, { useContext, useEffect, useState } from "react"
import { MapContainer, TileLayer as Basemap } from "react-leaflet"
import PropTypes from "prop-types"
import * as L from "leaflet"
import "leaflet/dist/leaflet.css"
import { DataContext } from "../../index"
import { pointOnLine } from "@turf/turf"
import PolygonStyle from "../../styles/polygon"
import { getLayerConfigs } from "../../../../../resouces/layerConfig"
import Legend from "./legend"
import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
const [layerConfig, layers] = getLayerConfigs(null, true)

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  legend: {
    position: "relative",
    bottom: "370px",
    left: "20px",
    zIndex: "800",
  },
})

const SearchPropertyOverviewMapWide = (props) => {
  const classes = useStyles()
  const { state } = useContext(DataContext)
  const [map, setMap] = useState(null)
  const [mapInitialized, setMapInitialized] = useState(false)
  const [polygonLayer, setPolygonLayer] = useState(null)
  const [selectedMarkers, setSelectedMarkers] = useState(null)
  const [layer, setLayer] = useState(null)

  const mapOptions = {
    scrollWheelZoom: false,
    zoomControl: true,
    center: [35.65173947607092, 139.54482101015265],
    zoom: 18,
    minZoom: 0,
    maxZoom: 20,
    attributionControl: false,
    ...props.mapOptions,
  }

  const icons = [
    L.icon({
      iconUrl: `/markers/marker_1.png`,
      iconSize: [25, 40],
      iconAnchor: [13, 40],
    }),
    L.icon({
      iconUrl: `/markers/marker_2.png`,
      iconSize: [25, 40],
      iconAnchor: [13, 40],
    }),
    L.icon({
      iconUrl: `/markers/marker_3.png`,
      iconSize: [25, 40],
      iconAnchor: [13, 40],
    }),
    L.icon({
      iconUrl: `/markers/marker_4.png`,
      iconSize: [25, 40],
      iconAnchor: [13, 40],
    }),
    L.icon({
      iconUrl: `/markers/marker_5.png`,
      iconSize: [25, 40],
      iconAnchor: [13, 40],
    }),
    L.icon({
      iconUrl: `/markers/marker_6.png`,
      iconSize: [25, 40],
      iconAnchor: [13, 40],
    }),
  ]

  const mapStyles = {
    width: "100%",
    height: "100%",
    border: "1px solid #333",
  }

  useEffect(() => {
    if (!map) {
      return
    }

    if (mapInitialized) {
      return
    }

    map.invalidateSize()

    console.log("[WideMap]", "initalized")

    let defaultLayer = L.tileLayer(
      "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
      { attribution: "", minZoom: 5, maxNativeZoom: 18, maxZoom: 28 }
    ).addTo(map)

    L.control
      .scale({ imperial: false, position: "bottomright", maxWidth: 200 })
      .addTo(map)

    setMapInitialized(true)
  }, [map])

  useEffect(() => {
    if (!mapInitialized) {
      return
    }

    if (!state.area.features[0]?.geometry?.coordinates) {
      return
    }

    selectedMarkers?.forEach((m) => {
      map.removeLayer(m)
    })

    let latlngs = []
    state.area?.features?.forEach((feature) => {
      feature.geometry?.coordinates.forEach((coords) => {
        coords.forEach((coord) => {
          latlngs.push([coord[1], coord[0]])
        })
      })
    })

    // 選択済みicの取得
    setSelectedMarkers(null)
    let markers = []
    state.transitIcData?.forEach((v) => {
      if (state.selectedTransitId.includes(v.id)) {
        latlngs.push([v.latitude, v.longitude])

        let idx = state.selectedTransitId.indexOf(v.id)
        let m = L.marker([v.latitude, v.longitude], { icon: icons[idx] })
        markers.push(m)
      }
    })
    state.transitTrainData?.forEach((v) => {
      if (state.selectedTransitId.includes(v.id)) {
        latlngs.push([v.latitude, v.longitude])

        let idx = state.selectedTransitId.indexOf(v.id)
        let m = L.marker([v.latitude, v.longitude], { icon: icons[idx] })
        markers.push(m)
      }
    })

    setSelectedMarkers([...markers])
    map.fitBounds(latlngs, { maxZoom: 16, padding: [20, 20] })

    setPolygonLayer(null)

    L.geoJSON(state.area, {
      style: PolygonStyle,
      onEachFeature(feature, layer) {
        setPolygonLayer(layer)
      },
    })
  }, [
    mapInitialized,
    state.area,
    state.transitTrainData,
    state.transitIcData,
    state.selectedTransitId,
  ])

  useEffect(() => {
    if (polygonLayer) {
      polygonLayer.addTo(map)
    }

    return () => {
      if (polygonLayer) {
        map.removeLayer(polygonLayer)
      }
    }
  }, [polygonLayer])

  useEffect(() => {
    selectedMarkers?.forEach((m) => {
      m.addTo(map)
    })

    return () => {
      selectedMarkers?.forEach((m) => {
        map.removeLayer(m)
      })
    }
  }, [selectedMarkers])

  useEffect(() => {
    if (!map || !state.selectedLayer) {
      if (layer) {
        map.removeLayer(layer)
      }
      setLayer(null)
      return
    }

    let l = layers[state.selectedLayer]
    l.setZIndex(200)
    l.addTo(map)
    setLayer(l)

    return () => {
      if (layer) {
        map.removeLayer(layer)
      }
      setLayer(null)
    }
  }, [map, state.selectedLayer])

  const onCreatedMap = (ref) => {
    setMap(ref)
  }

  return (
    <Box className={classes.root}>
      <MapContainer
        style={mapStyles}
        whenCreated={onCreatedMap}
        {...mapOptions}
      />
      {state.selectedLayer && <Legend className={classes.legend} />}
    </Box>
  )
}

SearchPropertyOverviewMapWide.propTypes = {
  mapOptions: PropTypes.any,
}

export default SearchPropertyOverviewMapWide
