export default {
  name: "PML（S造新耐震）",
  attribute: {
    title: "「地震リスク評価技術」（株式会社フジタ）",
  },
  query: `
SELECT cartodb_id,
       the_geom_webmercator,
       pml_s_shintaishin_is0_6 AS value
FROM "${process.env.REACT_APP_CARTO_USERNAME}".pml AS p
  `,
  css: `
#layer  {
  [value<10]{polygon-fill: #9fe187;}
  [value>=10][value<20]{polygon-fill: #d2e187;}
  [value>=20][value<30]{polygon-fill: #e1cc87;}
  [value>=30][value<40]{polygon-fill: #e1aa87;}
  [value>=40][value<50]{polygon-fill: #e19987;}
  [value>=50][value<60]{polygon-fill: #e18787;}
  [value>=60]{polygon-fill: #434141;}
  polygon-opacity: 0.4;
  line-width: 1;
  line-color: #FFFFFF;
  line-opacity: 0.5;
}
`,
  legend: [
    { color: "#9fe187", label: "〜 10%" },
    { color: "#d2e187", label: "10 〜 20%" },
    { color: "#e1cc87", label: "20 〜 30%" },
    { color: "#e1aa87", label: "30 〜 30%" },
    { color: "#e19987", label: "40 〜 40%" },
    { color: "#e18787", label: "50 〜 50%" },
    { color: "#434141", label: "60% 〜" },
  ],
}
