export const ActionType = {
  SetAdData: "SET_AD",
}

const RootDataReducer = (state, action) => {
  switch (action.type) {
    case ActionType.SetAdData:
      return {
        ...state,
        adData: action.value,
        adUserId: action.value?.AUTH_USER,
      }
    default:
      break
  }

  return state
}

export default RootDataReducer
