export default {
  name: "PML（振幅率）",
  attribute: {
    title: "「地震リスク評価技術」（株式会社フジタ）",
  },
  query: `
SELECT
    cartodb_id,
    the_geom_webmercator,
    amplification_factor AS value
FROM "${process.env.REACT_APP_CARTO_USERNAME}".pml
  `,
  css: `
#layer [value<0.5] {
  polygon-fill: #9fe187;
  polygon-opacity: 0.75;
}
#layer [value>=0.5][value<1.0] {
  polygon-fill: #d2e187;
  polygon-opacity: 0.75;
}
#layer [value>=1.0][value<1.5] {
  polygon-fill: #e1cc87;
  polygon-opacity: 0.75;
}
#layer [value>=1.5][value<2.0] {
  polygon-fill: #e1aa87;
  polygon-opacity: 0.75;
}
#layer [value>=2.0][value<2.5] {
  polygon-fill: #e19987;
  polygon-opacity: 0.75;
}
#layer [value>=2.5][value<3.0] {
  polygon-fill: #e18787;
  polygon-opacity: 0.75;
}
#layer [value>=3.0] {
  polygon-fill: #434141;
  polygon-opacity: 0.75;
}

#layer::outline {
  line-width: 1;
  line-color: #FFFFFF;
  line-opacity: 0.5;
}`,
  legend: [
    { color: "#9fe187", label: "〜 0.5" },
    { color: "#d2e187", label: "0.5 〜 1.0" },
    { color: "#e1cc87", label: "1.0 〜 1.5" },
    { color: "#e1aa87", label: "1.5 〜 2.0" },
    { color: "#e19987", label: "2.0 〜 2.5" },
    { color: "#e18787", label: "2.5 〜 3.0" },
    { color: "#434141", label: "3.0 〜" },
  ],
}
