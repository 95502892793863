import React, { useContext, useRef, useState } from "react"
import { MapContainer, TileLayer as Basemap } from "react-leaflet"
import { Box, Button, MenuItem, Select, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Table from "./table"
import LocalMap from "./map/local"
import WideMap from "./map/wide"
import { useReactToPrint } from "react-to-print"
import { DataContext } from "../index"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import Excel from "./excel"
import html2canvas from "html2canvas"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    marginTop: "16px",
  },
  map: {
    width: "100%",
    height: "auto",
    paddingTop: "calc(50%)",
    position: "relative",
  },
  excel: {
    width: "200px",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
})

const SearchPropertyOverview = (props) => {
  const { onSave } = props
  const { state } = useContext(DataContext)
  const { excelEnabled } = useState(true)
  const classes = useStyles()
  const wideMapRef = useRef(null)
  const localMapRef = useRef(null)

  return (
    <Box className={classes.root}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          marginRight: "16px",
          justifyContent: "end",
        }}
      >
        <Excel
          onSave={props.onSave}
          className={classes.excel}
          wideMapRef={wideMapRef}
          localMapRef={localMapRef}
        />
      </Box>
      <Typography>詳細情報</Typography>
      <Box>
        <Table />
      </Box>
      <Typography>広域地図</Typography>
      <Box className={classes.map} ref={wideMapRef}>
        <WideMap />
      </Box>
      <Typography>詳細地図</Typography>
      <Box className={classes.map} ref={localMapRef}>
        <LocalMap />
      </Box>
    </Box>
  )
}

SearchPropertyOverview.propTypes = {
  onSave: PropTypes.any,
}

export default SearchPropertyOverview
