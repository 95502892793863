import { useReducer } from "react"
import axios from "axios"
import initialState from "./state"
import reducer, { ActionType } from "./reducer"
import Get from "../../../../manager/storage"

const UseSearchActualData = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    isLoading: false,
  })

  const fetchStarted = () => {
    dispatch({ type: ActionType.FetchStarted })
  }
  const fetchCompleted = () => {
    dispatch({ type: ActionType.FetchCompleted })
  }

  const clearData = (data, callback) => {
    dispatch({ type: ActionType.Clear, value: data })
    callback && callback()
  }

  const setWord = (word) => {
    dispatch({ type: ActionType.SetWord, value: word })
  }

  const setMapOptions = (mapOptions, callback) => {
    dispatch({
      type: ActionType.SetMapOptions,
      value: { ...state.mapOptions, ...mapOptions },
    })
    callback && callback()
  }

  const setSelected = (cartodbIds, callback) => {
    dispatch({ type: ActionType.SetSelected, value: cartodbIds })

    let query = `SELECT * FROM "${process.env.REACT_APP_CARTO_USERNAME}".v_sekoujisseki`

    if (cartodbIds.length === 0) {
      query += " LIMIT 0"
    } else {
      query += ` WHERE cartodb_id IN (${cartodbIds.join(",")})`
    }
    state.mapSource.setQuery(query)

    callback && callback()
  }

  const setViewMode = (viewMode) => {
    dispatch({ type: ActionType.SetViewMode, value: viewMode })
  }

  const startLoading = (callback, cancelCallback) => {
    dispatch({ type: ActionType.SetLoading, value: true, cancelCallback })
    callback && callback()
  }

  const stopLoading = () => {
    //    state.stopLoadCallback && state.stopLoadCallback()
    dispatch({ type: ActionType.SetLoading, value: false, callback: null })
  }

  const setFocusedPoint = (latlng) => {
    dispatch({ type: ActionType.SetFocusedPoint, value: latlng })
  }

  const setFilterModel = (filterModel) => {
    dispatch({ type: ActionType.SetFilterModel, value: filterModel })
  }

  const setDataType = (types) => {
    dispatch({ type: ActionType.SetDataType, value: types })
  }

  return {
    state,
    fetchStarted,
    fetchCompleted,
    clearData,
    setWord,
    setSelected,
    setViewMode,
    startLoading,
    stopLoading,
    setMapOptions,
    setFocusedPoint,
    setFilterModel,
    setDataType,
  }
}

export default UseSearchActualData
