import React, {useContext, useEffect, useState} from "react"
import { makeStyles } from "@mui/styles"
import Header from "../common/header"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material"

import StandardView from "../common/view"
import { Keys as StorageKeys } from "../../manager/storage"
import { useHistory } from "react-router-dom"
import ContentLoader from "react-content-loader"
import { Query } from "../../manager/carto"
import {RootDataContext} from "../../App";
const useStyles = makeStyles({
  root: {
    width: "100vw",
    height: "100vh",
    overflow: "auto",
  },
  main: {
    margin: "40px auto 0 auto",
  },
  menu_button: {
    width: "calc(100% - 40px) !important",
    height: "80px",
    margin: "20px !important",
    color: "white !important",
    fontSize: "18px !important",
    backgroundColor: "#999 !important",
    "&:hover": {
      backgroundColor: "#9569eb !important",
    },
  },
  list_item_date: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black",
    backgroundColor: "beige",
    padding: "5px 20px",
  },
  list_item_button: {
    backgroundColor: "#999 !important",
    color: "white !important",
    textAlign: "center !important",
    borderRadius: "16px !important",
    height: "40px !important",
    marginLeft: "20px !important",
    fontWeight: "bold !important",
    "&:hover": {
      backgroundColor: "#9569eb !important",
    },
  },
  empty_result: {
    color: "#999",
  },
})

const Root = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const {rootState} = useContext(RootDataContext)

  const ListLoader = (key) => (
    <ContentLoader width="100%" height="46">
      <rect x="8" y="2" rx="1" ry="1" width="200" height="40" />
      <rect x="230" y="2" rx="5" ry="5" width="90" height="40" />
    </ContentLoader>
  )

  const [savedActualSearch, setSavedActualSearch] = useState(null)
  const [savedPropertySearch, setSavedPropertySearch] = useState(null)

  useEffect(() => {
    document.title = "施工実績"
    dayjs.extend(customParseFormat)

    console.log(process)

    getActualSearch()
    getPropertySearch()
  }, [])

  const getActualSearch = () => {

    console.log(rootState.adData)
    Query(
      `
        SELECT * FROM "${process.env.REACT_APP_CARTO_USERNAME}".saved_search_actual
        WHERE user_id = '${rootState.adUserId?.replace(/\\/g,'\\\\')}' OR user_id IS NULL
        ORDER BY created_at DESC LIMIT 10`
    ).then((ret) => {
      setSavedActualSearch(ret?.data?.rows ?? [])
    })
  }

  const getPropertySearch = () => {
    Query(
      `
        SELECT * FROM "${process.env.REACT_APP_CARTO_USERNAME}".saved_search_property
        WHERE user_id = '${rootState.adUserId?.replace(/\\/g, '\\\\')}' OR user_id IS NULL
        ORDER BY created_at DESC LIMIT 10`
    ).then((ret) => {
      setSavedPropertySearch(ret?.data?.rows ?? [])
    })
  }

  const toSearchActual = (param) => {
    history.push(`/search/actual/${param ?? ""}`)
  }

  const toSearchProperty = (param) => {
    history.push(`/search/property/${param ?? ""}`)
  }

  return (
    <StandardView className={classes.root}>
      <Header title="" disableHomeButton={true} />
      <Box
        display="flex"
        flexDirection="row"
        className={classes.main}
        gap="0 48px"
      >
        <Box>
          <Typography variant="h5">施工実績一覧の作成はこちらから</Typography>
          <Button
            className={classes.menu_button}
            onClick={() => {
              toSearchActual()
            }}
          >
            施工実績検索
          </Button>
          <Box>
            <Typography>最近の検索結果(10件まで)</Typography>
            <List>
              {savedActualSearch &&
                savedActualSearch
                  .sort((v1, v2) => {
                    return dayjs(v1.created_at) < dayjs(v2.created_at) ? 1 : -1
                  })
                  .map((v, i) => (
                    <ListItem key={i.toString()}>
                      <ListItemText
                        primary={v.name}
                        className={classes.list_item_date}
                      />
                      <ListItemButton
                        className={classes.list_item_button}
                        onClick={() => {
                          toSearchActual(v.uuid)
                        }}
                      >
                        <ListItemText primary="開く" />
                      </ListItemButton>
                    </ListItem>
                  ))}
              {savedActualSearch && savedActualSearch.length === 0 && (
                <Typography className={classes.empty_result}>
                  保存データが有りません
                </Typography>
              )}
              {!savedActualSearch &&
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                  <ListItem key={i.toString()}>
                    <ListLoader />
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
        <Box>
          <Typography variant="h5">物件概要所の作成はこちらから</Typography>
          <Button
            onClick={() => toSearchProperty()}
            className={classes.menu_button}
          >
            土地プロパティ検索
          </Button>
          <Typography>最近の検索結果(10件まで)</Typography>
          <List>
            {savedPropertySearch &&
              savedPropertySearch.length > 0 &&
              savedPropertySearch
                .sort((v1, v2) => {
                  return dayjs(v1.created_at) < dayjs(v2.created_at) ? 1 : -1
                })
                .map((v, i) => (
                  <ListItem key={i.toString()}>
                    <ListItemText
                      primary={v.name}
                      className={classes.list_item_date}
                    />
                    <ListItemButton
                      className={classes.list_item_button}
                      onClick={() => {
                        toSearchProperty(v.uuid)
                      }}
                    >
                      <ListItemText primary="開く" />
                    </ListItemButton>
                  </ListItem>
                ))}
            {savedPropertySearch && savedPropertySearch.length === 0 && (
              <Typography className={classes.empty_result}>
                保存データが有りません
              </Typography>
            )}
            {!savedPropertySearch &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <ListItem key={i.toString()}>
                  <ListLoader />
                </ListItem>
              ))}
          </List>
        </Box>
      </Box>
    </StandardView>
  )
}

export default Root
