import React, { useContext, useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import {
  Box,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import { DataContext } from "./index"
import PropTypes from "prop-types"
import { Get, Set } from "../../../manager/storage"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles({
  root: {
    width: "calc(100% - 40px)",
    margin: "20px auto",
  },
})

const SearchControl = (props) => {
  const { onExport, onSave } = props
  const classes = useStyles()
  const { state, setWord, setViewMode, setFilterModel, setDataType } =
    useContext(DataContext)
  const [inputValue, setInputValue] = useState(null)

  const history = useHistory()

  useEffect(() => {
    setInputValue(state.filterWords)
  }, [state.filterWords])

  const onViewMode = (_, v) => {
    //    console.log('[onViewMode]', v)
    setViewMode(v)
  }

  const onBlur = () => {
    setWord(inputValue)
  }

  const onFilterReset = () => {
    Set("search_actual_list_column_saved", {})
    setFilterModel({})
  }

  const onChange = (e) => {
    setInputValue(e.target.value)
  }

  const onKeyDown = (e) => {
    if (e.code === "Enter" && !e.nativeEvent.isComposing) {
      setWord(inputValue)
    }
  }

  const onDataType = (e, v) => {
    console.log(e, v)

    setDataType([v])
/*
    if (state.dataType.includes(v)) {
      let d = state.dataType.filter(k => v !== k)
      setDataType(Array.from(d))
    } else {
      let d = state.dataType
      d.push(v)
      setDataType(d)
    }
 */
  }

  const onSearch = () => {
    setWord(inputValue)
  }

  return (
    <div className={classes.root}>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
        }}
      >
        <TextField
          style={{
            marginRight: "1em",
            width: "300px",
          }}
          id="search-words"
          size="small"
          label="キーワード検索"
          placeholder="キーワードを入力してください"
          variant="outlined"
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={inputValue}
        />
        <Button
          style={{
            marginRight: "1em",
          }}
          size="large"
          variant="contained"
          onClick={onSearch}
        >
          絞り込み
        </Button>

        <Button
          style={{
            marginRight: "1em",
          }}
          size="large"
          variant="contained"
          onClick={onFilterReset}
        >
          条件をクリア
        </Button>

        <ToggleButtonGroup
          value={state.viewMode}
          color="primary"
          size="small"
          exclusive={true}
          style={{
            margin: "auto 0",
            alignItems: "center",
            marginRight: "1.5em",
          }}
          onChange={onViewMode}
        >
          <ToggleButton value="all">すべて</ToggleButton>
          <ToggleButton value="select">選択のみ</ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          value={state.dataType}
          color="primary"
          size="small"
          exclusive={true}
          style={{
            margin: "auto 0",
            alignItems: "center",
            marginRight: "1.5em",
          }}
          onChange={onDataType}
        >
          <ToggleButton value="kenchiku">建築</ToggleButton>
          <ToggleButton value="doboku">土木</ToggleButton>
          <ToggleButton value="fudosan">不動産</ToggleButton>
        </ToggleButtonGroup>

        <Button
          size="large"
          variant="outlined"
          onClick={onExport}
          style={{ marginRight: "1.0em" }}
        >
          エクセル出力
        </Button>

        <Button size="large" variant="outlined" onClick={onSave} style={{}}>
          条件保存
        </Button>
      </Box>
    </div>
  )
}

SearchControl.propTypes = {
  viewMode: PropTypes.string,
  onViewMode: PropTypes.func,
  onExport: PropTypes.func,
  onSave: PropTypes.func,
}

export default SearchControl
