import initialState from "./state"

export const ActionType = {
  Clear: "CLEAR",
  FetchStarted: "FETCH_STARTED",
  FetchCompleted: "FETCH_COMPLETED",
  SetData: "SET_DATA",
  SetWord: "SET_WORD",
  SetSelected: "SET_SELECTED",
  AddSelected: "ADD_SELECTED",
  RemoveSelected: "REMOTE_SELECTED",
  SetQueryParams: "SET_QUERY_PARAMS",
  AddQueryParams: "ADD_QUERY_PARAMS",
  SetViewMode: "SET_VIEW_MODE",
  SetLoading: "SET_LOADING",
  SetMapSource: "SET_MAP_SOURCE",
  SetMapOptions: "SET_MAP_OPTIONS",
  SetFocusedPoint: "SET_FOCUSED_POINT",
  SetFilterModel: "SET_FILTER_MODEL",
  SetDataType: "SET_DATA_TYPE",
}

const SearchActualDataReducer = (state, action) => {
  switch (action.type) {
    case ActionType.FetchStarted:
      return { ...state, isLoading: true }
    case ActionType.FetchCompleted:
      return { ...state, isLoading: false }
    case ActionType.SetData:
      return { ...state, data: action.value ?? state.rows ?? [] }
    case ActionType.Clear:
      return { ...initialState, ...(action.value ?? {}) }
    case ActionType.SetWord:
      return { ...state, filterWords: action.value }
    case ActionType.SetSelected:
      return { ...state, selected: action.value }
    case ActionType.AddSelected:
      return { ...state, selected: state.selected.push(action.value) }
    case ActionType.RemoveSelected:
      return {
        ...state,
        selected: state.selected.filter((v) => action.value !== v),
      }
    case ActionType.SetQueryParams:
      return { ...state, queryParams: action.value }
    case ActionType.AddQueryParams:
      return { ...state, queryParams: state.queryParams.push(action.value) }
    case ActionType.SetViewMode:
      return { ...state, viewMode: action.value }
    case ActionType.SetLoading:
      return {
        ...state,
        isLoading: action.value,
        stopLoadCallback: action.cancelCallback,
      }
    case ActionType.SetMapSource:
      return { ...state, mapSource: action.value }
    case ActionType.SetMapOptions:
      return { ...state, mapOptions: action.value }
    case ActionType.SetFocusedPoint:
      return { ...state, focusedPoint: action.value }
    case ActionType.SetFilterModel:
      return { ...state, filterModel: action.value }
    case ActionType.SetDataType:
      return { ...state, dataType: action.value }
    default:
      break
  }
  return state
}

export default SearchActualDataReducer
