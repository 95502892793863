import React, {useContext, useState} from "react"
import { Get as GetStorage, Set as SetStorage } from "../../../manager/storage"
import UseSearchPropertyData from "./data"
import { makeStyles } from "@mui/styles"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import StandardView from "../../common/view"
import Header from "../../common/header"
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material"
import Map from "./map"
import Transit from "./transit"
import Overview from "./overview"
import SearchAddress from "./search/address"
import dayjs from "dayjs"
import { Query } from "../../../manager/carto"
import UUID from "uuidjs"
import {RootDataContext} from "../../../App";

export const DataContext = React.createContext()

const useStyles = makeStyles({
  root: {
    margin: "20px",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  root_box: {
    width: "calc(50vw - 40px)",
    flexGrow: 1,
  },
  customer_box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  customer_label: {},
  customer_input: {
    width: "350px",
  },
  map_footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "40px",
  },
})

let useSearchPropertyData

const SearchProperty = (props) => {
  const classes = useStyles()
  const params = useParams()

  const [saveSuccess, setSaveSuccess] = useState(null)
  const [mapSubmitDisabled, setMapSubmitDisabled] = useState(true)

  const useSearchPropertyData = UseSearchPropertyData()

  const { state, clearData, setCustomer } = useSearchPropertyData
  const {rootState} = useContext(RootDataContext)
  useEffect(() => {
    document.title = "土地プロパティ検索"

    if (params.uuid) {
      Query(
        `SELECT value FROM "${process.env.REACT_APP_CARTO_USERNAME}".saved_search_property WHERE uuid = '${params.uuid}'`
      ).then((ret) => {
        const val = ret?.data?.rows[0]["value"]
        if (val) {
          clearData(JSON.parse(val))
        }
      })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    console.log(state.area)
    setMapSubmitDisabled(!state.area?.features.length)
  }, [state.area])

  const onChangeCustomer = (e) => {
    setCustomer(e.target.value)
  }

  const onSave = () => {
    let d = dayjs().format("YYYY-MM-DD-HH-mm")
    let key = "saved_search_property_" + d

    const value = {
      place: state.place,
      customer: state.customer,
      area: state.area,
      area_info: state.area_info,
      transitIcData: state.transitIcData,
      transitTrainData: state.transitTrainData,
      selectedTransitId: state.selectedTransitId,
    }

    Query(
      `
    INSERT INTO "${process.env.REACT_APP_CARTO_USERNAME}".saved_search_property (uuid, name, value, user_id)
     VALUES ('${UUID.generate()}', '${dayjs().format(
        "YYYY年M月D日H時m分"
      )}', '${JSON.stringify(value)}', '${rootState.adUserId?.replace(/\\/, '\\\\')}')
    `
    )

    SetStorage(key, {
      place: state.place,
      customer: state.customer,
      area: state.area,
      area_info: state.area_info,
      transitIcData: state.transitIcData,
      transitTrainData: state.transitTrainData,
      selectedTransitId: state.selectedTransitId,
    })
    setSaveSuccess(true)
    setTimeout(() => {
      setSaveSuccess(false)
    }, 1300)
    console.log(d)
  }

  return (
    <DataContext.Provider value={useSearchPropertyData}>
      <StandardView>
        <Header title="土地プロパティ検索" />
        <Box className={classes.root}>
          <Box className={classes.root_box}>
            <SearchAddress />
            <Map />
          </Box>
          <Box className={classes.root_box}>
            <Box className={classes.customer_box}>
              <Typography className={classes.customer_label} variant="h6">
                お客様名（物件概要書タイトル）：
              </Typography>
              <TextField
                className={classes.customer_input}
                value={state.customer}
                onChange={onChangeCustomer}
                variant="outlined"
                size="small"
                label={state.customer ? "" : "お客様名（物件概要書タイトル）"}
              />
            </Box>
            <Box>
              <Transit />
            </Box>
            {(state.area?.features?.length ?? 0) > 0 && (
              <Overview onSave={onSave} />
            )}
          </Box>
        </Box>
      </StandardView>
      <Snackbar
        open={saveSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          保存しました
        </Alert>
      </Snackbar>
    </DataContext.Provider>
  )
}

export default SearchProperty
