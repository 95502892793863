export default {
  name: "竜巻発生地点",
  attribute: {
    title: "「国土数値情報（竜巻等の突風データ）」（国土交通省）",
    url: "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A30b.html",
    sub_title:
      "※竜巻発生地点は2次メッシュごとに発生地点数を集計した値を用いた。",
  },
  query: `SELECT *, tatsumaki_count AS value FROM "${process.env.REACT_APP_CARTO_USERNAME}".tatsumaki`,
  css: `
#layer [value=1] {
  polygon-fill: #9fe187;
  polygon-opacity: 0.75;
}
#layer [value>=2][value<=3] {
  polygon-fill: #d2e187;
  polygon-opacity: 0.75;
}
#layer [value>=4][value<=5] {
  polygon-fill: #e1cc87;
  polygon-opacity: 0.75;
}
#layer [value>=6][value<=7] {
  polygon-fill: #e1aa87;
  polygon-opacity: 0.75;
}
#layer [value>=8][value<=9] {
  polygon-fill: #e19987;
  polygon-opacity: 0.75;
}
#layer [value>=10] {
  polygon-fill: #e18787;
  polygon-opacity: 0.75;
}

#layer::outline {
  line-width: 1;
  line-color: #FFFFFF;
  line-opacity: 0.5;
}`,
  legend: [
    { color: "#9fe187", label: "1回" },
    { color: "#d2e187", label: "2〜3回" },
    { color: "#e1cc87", label: "4〜5回" },
    { color: "#e1aa87", label: "6〜7回" },
    { color: "#e19987", label: "8〜9回" },
    { color: "#e18787", label: "10回以上" },
  ],
}
