import React, { useContext } from "react"
import { Button, Typography } from "@mui/material"
import PropTypes from "prop-types"
import {
  cellAligment,
  cellAlignmentCenter,
  cellAllRoundBorderRange,
  cellMergeRanges,
  cellRoundBorders,
  cellValues,
  cellWidths, cellWrapText,
  exportExcel,
} from "../../../../../manager/excel"
import { DataContext } from "../../index"
import ReactDOM from "react-dom"
import domtoimage from "dom-to-image"
import { RootDataContext } from "../../../../../App"
const ExcelJS = require("exceljs")

const maruNum = ["", "①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨", "⑩"]

const SearchPropertyOverviewExcel = (props) => {
  const { state } = useContext(DataContext)
  const { rootState } = useContext(RootDataContext)

  const getSheetTemplate = (workbook) => {
    workbook.calcProperties.fullCalcOnLoad = true

    const sheet = workbook.addWorksheet("物件概要書", {
      views: [{ showGridLines: false }],
    })
    sheet.pageSetup = {
      ...sheet.pageSetup,
      margins: {
        left: 0.7,
        right: 0.7,
        top: 0.75,
        bottom: 0.75,
        header: 0.0,
        footer: 0.0,
      },
      paperSize: 8,
      orientation: "landscape",
      printArea: "A1:F28",
    }
    sheet.properties.defaultRowHeight = 30
    sheet.views = [{ style: "pageBreakPreview" }]
    sheet.getCell("A1:F28").font = { size: 12 }
    sheet.getCell("A1").font = { size: 18 }
    sheet.getCell("A1").alignment = { horizontal: "left", vertical: "middle" }
    sheet.getCell("F2").alignment = { horizontal: "left", vertical: "middle" }
    sheet.getCell("F15").alignment = { horizontal: "left", vertical: "middle" }
    cellWidths(sheet, {
      A: 8.5,
      B: 8.5,
      C: 17.6,
      D: 46.0,
      E: 8.5,
      F: 100.0,
    })

    cellMergeRanges(sheet, [
      "A2:B2",
      "A3:A5",
      "B4:B5",
      "A6:A10",
      "B7:B10",
      "A11:A15",
      "B11:B14",
      "C11:D11",
      "C12:D12",
      "C13:D13",
      "C14:D14",
      "C15:D15",
      "A16:A26",
      "B16:C16",
      "B17:C17",
      "B18:C18",
      "B19:B23",
      "B24:C24",
      "B25:C25",
      "B26:C26",
      "A27:B28",
      "C27:D28",
      "F3:F10",
      "F16:F24",
      "F25:F28",
      "C2:D2",
      "C3:D3",
    ])

    cellValues(sheet, {
      A2: "取引形態",
      A3: "所在地",
      B3: "住所",
      B4: "交通",
      C4: "最寄りの鉄道駅",
      C5: "最寄りのIC",
      A6: "土地",
      B6: "面積",
      B7: "法規制",
      C6: "GPSで測定(参考)",
      C7: "区域区分",
      C8: "用途地域",
      C9: "建蔽率",
      C10: "容積率",
      A11: "道路",
      B11: "接面道路\n幅員",
      B15: "私道負担",
      C11: "（　　　　　）　　　　　　側　　　　　　　幅員　　　　　　　mに　　　　　　　　　m接道\t",
      C12: "（　　　　　）　　　　　　側　　　　　　　幅員　　　　　　　mに　　　　　　　　　m接道\t",
      C13: "（　　　　　）　　　　　　側　　　　　　　幅員　　　　　　　mに　　　　　　　　　m接道\t",
      C14: "（　　　　　）　　　　　　側　　　　　　　幅員　　　　　　　mに　　　　　　　　　m接道\t",
      A16: "BCP",
      B16: "洪水浸水想定区域",
      B17: "津波浸水想定区域",
      B18: "土砂災害警戒区域",
      B19: "PML",
      C19: "振幅率",
      C20: "RC造旧耐震",
      C21: "RC造新耐震",
      C22: "S造旧耐震",
      C23: "S造新耐震",
      B24: "地表面最大速度",
      B25: "竜巻発生地域",
      B26: "",
      A27: "その他",
      F2: "■位置図",
      F15: "■詳細図",
    })

    cellAllRoundBorderRange(sheet, [1, 2], [4, 28])
    cellAlignmentCenter(sheet, [
      "A2",
      "B2",
      "A3",
      "B3",
      "B4",
      "C4",
      "C5",
      "A6",
      "B6",
      "C6",
      "B7",
      "C7",
      "C8",
      "C9",
      "C10",
      "A11",
      "B11",
      "B15",
      "C11",
      "C12",
      "C13",
      "C14",
      "A16",
      "B16",
      "B17",
      "B18",
      "B19",
      "C19",
      "C20",
      "C21",
      "C22",
      "C23",
      "B24",
      "B25",
      "B26",
      "A27",
    ])
    cellAligment(
      sheet,
      [
        "C2",
        "C3",
        "C15",
        "D4",
        "D5",
        "D6",
        "D7",
        "D8",
        "D9",
        "D10",
        "D16",
        "D17",
        "D18",
        "D19",
        "D20",
        "D21",
        "D22",
        "D23",
        "D24",
        "D25",
        "D26",
      ],
      { vertical: "middle", horizontal: "left", indent: 1 }
    )
    cellRoundBorders(sheet, ["F3", "F16", "F21"])

    cellWrapText(sheet, ["B11"])

    // 名前
    sheet.getCell("A1").value =
      (state.customer ? `${state.customer}様向け` : "") + "物件概要書"

    // 行の高さを1行ずつセット(なぜかモニタ限界以上はdefaultHeightが無視されるため)
    for(let i = 1; i < 30; i++) {
      sheet.getRow(i).height = 30
    }

    return sheet
  }

  const onExport = async () => {
    props.onGenerating && props.onGenerating()
    props.onSave && props.onSave()

    const workbook = new ExcelJS.Workbook()
    const worksheet = getSheetTemplate(workbook)

    console.log("[ExportExcel]", state.area_info)

    // 住所
    worksheet.getCell("C3").value = state.area_info?.address ?? ""

    // 最寄り
    let ics = []
    let trains = []
    for (let key in state.selectedTransitId) {
      let id = state.selectedTransitId[key]
      if (state.transitIcData.find((e) => e.id === id)) {
        let v = state.transitIcData.find((e) => e.id === id)
        ics.push(`${maruNum[parseInt(key) + 1]}: ${v.name} (${v.length})`)
      } else if (state.transitTrainData.find((e) => e.id === id)) {
        let v = state.transitTrainData.find((e) => e.id === id)
        trains.push(`${maruNum[parseInt(key) + 1]}: ${v.name} (${v.length})`)
      }
    }
    if (ics.length > 0) {
      worksheet.getCell("D5").value = ics.join(",")
    }
    if (trains.length > 0) {
      worksheet.getCell("D4").value = trains.join(",")
    }
    // 面積
    worksheet.getCell("D6").value = setValue(state.area_info?.area)
    // 用途区域
    worksheet.getCell("D8").value = setValue(
      state.area_info?.yoto?.filter((e) => e !== null).join(",")
    )
    worksheet.getCell("D9").value = setValue(
      state.area_info?.kenpe?.filter((e) => e !== null).join(",")
    )
    worksheet.getCell("D10").value = setValue(
      state.area_info?.yoseki?.filter((e) => e !== null).join(",")
    )
    worksheet.getCell("D18").value = setValue(
      state.area_info?.dosyasaigai?.filter((e) => e !== null).join(",")
    )
    worksheet.getCell("D16").value = setValue(state.area_info?.kouzui)
    // BCP - 津波再代親水想定
    worksheet.getCell("D17").value = setValue(state.area_info?.tsunami)
    // PML - 振幅率
    worksheet.getCell("D19").value = setValue(
      state.area_info?.pml?.amplification_factor
    )
    // PML - RC造旧耐震
    worksheet.getCell("D20").value = setValue(state.area_info?.pml?.rc_kyu)
    // PML - RC造新耐震
    worksheet.getCell("D21").value = setValue(state.area_info?.pml?.rc_shin)
    // PML - S造旧耐震
    worksheet.getCell("D22").value = setValue(state.area_info?.pml?.s_kyu)
    // PML - S造新耐震
    worksheet.getCell("D23").value = setValue(state.area_info?.pml?.s_shin)
    // BCP 地表面最大速度
    worksheet.getCell("D24").value = setValue(
      state.area_info?.pml?.surface_velocity
    )
    // 竜巻地点
    worksheet.getCell("D25").value = setValue(state.area_info?.tatsumaki)

    // 画像の取得
    if (props.wideMapRef.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const element = ReactDOM.findDOMNode(props.wideMapRef.current)
      const url = await domtoimage.toPng(element)
      const wideImage = workbook.addImage({
        base64: url,
        extension: "png",
      })
      worksheet.addImage(wideImage, "F3:F12")
    }

    if (props.localMapRef.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const element = ReactDOM.findDOMNode(props.localMapRef.current)
      const url = await domtoimage.toPng(element)
      const wideImage = workbook.addImage({
        base64: url,
        extension: "png",
      })
      worksheet.addImage(wideImage, "F14:F24")
    }

    // ユーザ名(仮でADのユーザID)
    let f21 = worksheet.getCell("F25")
    f21.alignment = { vertical: "top", horizontal: "left" }
    f21.value = "担当者：" + setValue(rootState.adUserId)

    props.onPrepareDownload && props.onPrepareDownload()

    exportExcel(workbook)
      .then(() => {
        props.onDownloadFinished && props.onDownloadFinished()
      })
      .catch((e) => {
        props.onDownloadError && props.onDownloadError(e)
      })
  }

  const setValue = (value, emptyText = "該当なし") => {
    console.log(value)
    if (value && typeof value === "number") {
      return value.toLocaleString()
    }
    if ((value?.length ?? 0) === 0) {
      console.log("↑該当なし")
      return emptyText
    }
    return value
  }

  const _getImageFromElement = (node) => {
    return new Promise((resolve, reject) => {})
  }

  return (
    <Button
      className={props.className}
      style={props.style}
      variant="outlined"
      onClick={onExport}
    >
      <Typography>Excel出力</Typography>
    </Button>
  )
}

SearchPropertyOverviewExcel.propTypes = {
  onGenerating: PropTypes.func,
  onPrepareDownload: PropTypes.func,
  onDownloadError: PropTypes.func,
  onDownloadFinished: PropTypes.func,
  enabled: PropTypes.bool,
  style: PropTypes.any,
  className: PropTypes.string,
  wideMapRef: PropTypes.any,
  localMapRef: PropTypes.any,
  onSave: PropTypes.func,
}

export default SearchPropertyOverviewExcel
