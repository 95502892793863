import { Query } from "../../../../manager/carto"

const distinctFilterParams = {
  values: (params) => {
    console.log("[DistinctFilterParams]", params)
    if (params.colDef.field) {
      Query(
        `SELECT DISTINCT ${params.colDef.field} 
                FROM "${process.env.REACT_APP_CARTO_USERNAME}".v_sekoujisseki`
      )
        .then((ret) => {
          console.log("Distinct result", ret)
          let values = ["(空白・該当なし)"].concat(
            ret.data.rows.flatMap((v) => {
              if (v[params.colDef.field]) {
                return [v[params.colDef.field]]
              } else {
                return []
              }
            })
          )
          params.success(values)
        })
        .catch((e) => {
          params.success([])
        })
    }
    params.success([])
  },
}

export default [
  {
    width: 40,
    pinned: true,
    resizable: false,
    sortable: false,
    filter: false,
    floatingFilter: false,
    checkboxSelection: true,
  },
  {
    headerName: "建築",
    children: [
      {
        headerName: "発注者",
        field: "client",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "完了年度",
        field: "completion_year",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "工事場所",
        field: "location",
        filter: "agTextColumnFilter",
      },
      { headerName: "構造", field: "structure", filter: "agTextColumnFilter" },
      {
        headerName: "設計事務所",
        field: "design",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "JV比率",
        field: "jv_ratio",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "請負金最終",
        field: "amount_sold",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "施工床面積",
        field: "construction_floor_area",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "建物名称",
        field: "name",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "地上階数",
        field: "number_ground_floors",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "敷地面積",
        field: "site_area",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "特殊設備名称1",
        field: "special_equipment_name_1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊工法1",
        field: "special_method_1",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "施設用途-大項目名称1",
        field: "use_major_classification_name_1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "施設用途-中項目名称1",
        field: "use_minor_classification_name_1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "発注区分",
        field: "_order",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "請負金受注",
        field: "amount_order",
        filter: "agNumberColumnFilter",
      },
      { headerName: "支店名", field: "branch", filter: "agTextColumnFilter" },
      {
        headerName: "棟名称1",
        field: "building_name",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特性1",
        field: "charasteric_1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特性2",
        field: "charasteric_2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特性3",
        field: "charasteric_3",
        filterParams: distinctFilterParams,
      },
      { headerName: "所長", field: "director",         filterParams: distinctFilterParams,
      },
      {
        headerName: "工期終了",
        field: "end_construction_period",
        filter: "agNumberColumnFilter",
      },
      { headerName: "軒高", field: "height", filter: "agNumberColumnFilter" },
      {
        headerName: "JV区分",
        field: "jv_or_independent",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "法定床面積",
        field: "legal_floor_area",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "採用工法-大項目名称1",
        field: "major_name_100",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "採用工法-大項目名称2",
        field: "major_name_200",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "採用工法-大項目名称3",
        field: "major_name_300",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "採用工法-中項目名称1",
        field: "minor_name_100",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "採用工法-中項目名称2",
        field: "minor_name_200",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "採用工法-中項目名称3",
        field: "minor_name_300",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "地下階数",
        field: "number_basements",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "病床数",
        field: "number_beds",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "棟数",
        field: "number_buildings",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "戸数",
        field: "number_rooms",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "座席数",
        field: "number_seats",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "受注年度",
        field: "order_year",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "原発注者",
        field: "original_client",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "性能１1",
        field: "performance_1_1",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "性能１2",
        field: "performance_1_2",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "性能１3",
        field: "performance_1_3",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "性能２1",
        field: "performance_2_1",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "性能２2",
        field: "performance_2_2",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "性能２3",
        field: "performance_2_3",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "杭長1",
        field: "pile_length",
        filterParams: distinctFilterParams,
      },
      { headerName: "杭種1", field: "pile_type",         filterParams: distinctFilterParams,
      },
      {
        headerName: "官民区分",
        field: "public_or_private",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "登録範囲1",
        field: "registration_range_1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "登録範囲2",
        field: "registration_range_2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "登録範囲3",
        field: "registration_range_3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "営業職員",
        field: "sales_staff",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊設備1",
        field: "special_equipment_1",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "特殊設備2",
        field: "special_equipment_2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊設備3",
        field: "special_equipment_3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊設備名称2",
        field: "special_equipment_name_2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊設備名称3",
        field: "special_equipment_name_3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊設備性能1",
        field: "special_equipment_performance_1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊設備性能2",
        field: "special_equipment_performance_2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊設備性能3",
        field: "special_equipment_performance_3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊工法2",
        field: "special_method_2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "特殊工法3",
        field: "special_method_3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工期開始",
        field: "start_construction_period",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "施設用途-大項目1",
        field: "use_major_classification_1",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "施設性能-大項目2",
        field: "use_major_classification_2",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "施設性能-大項目3",
        field: "use_major_classification_3",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "施設性能-大項目名称2",
        field: "use_major_classification_name_2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "施設性能-大項目名称3",
        field: "use_major_classification_name_3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "施設用途-中項目1",
        field: "use_minor_classification_1",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "施設性能-中項目2",
        field: "use_minor_classification_2",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "施設性能-中項目名称2",
        field: "use_minor_classification_name_2",
        filterParams: distinctFilterParams,
      },
    ],
  },
  {
    headerName: "土木",
    children: [
      {
        headerName: "発注者名称",
        field: "client",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "発注者区分",
        field: "client_cd",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "完了年度",
        field: "completion_year",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "当社分請負金税抜き",
        field: "Contract_Price",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "JV当社持分比率",
        field: "jv_ratio",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "工事場所名自",
        field: "location",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "設計事務所名",
        field: "design",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工事略称コード",
        field: "Kouji_CD",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "正式工事名称",
        field: "Kouji_Kenmei",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "支店名",
        field: "shiten_nm",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "専門委員会副",
        field: "adviser_fuku",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "専門委員会主",
        field: "adviser_main",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "全体請負金税抜き",
        field: "amount_whole_wo_tax",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "施工条件",
        field: "construction_condition",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "施工方法",
        field: "construction_method",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工事概要工事規模",
        field: "construction_scale",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工種名",
        field: "construction_type",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工事場所名至",
        field: "end_location_nm",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "環境対策",
        field: "Environmental_measures",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工事略称名称",
        field: "Kouji_NM",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "主要工事数量規格",
        field: "main_construction_std",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "正式企業体名",
        field: "official_jv_nm",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "安全対策",
        field: "safety_measures",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "事業概要",
        field: "summary",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "その他技術的特記事項",
        field: "technical_special_note",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "当社分請負金税込み",
        field: "amount_w_tax",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "全体請負金税込み",
        field: "amount_whole_w_tax",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "契約月",
        field: "contract_month",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "契約年",
        field: "contract_year",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "コリンズ登録番号",
        field: "corins_no",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "コリンズ登録番号1",
        field: "corins_no1",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "コリンズ登録番号2",
        field: "corins_no2",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "社員番号",
        field: "employee_number",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工期終日",
        field: "end_construction_day",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "工期終月",
        field: "end_construction_month",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "工期終年",
        field: "end_construction_year",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "担当者従事期間至",
        field: "end_engagement",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工事場所コード至",
        field: "end_location_cd",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "外部表彰",
        field: "external_award",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "JV形態",
        field: "jv_category",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "JV構成コード",
        field: "jv_cd",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "JV実態内容",
        field: "jv_detail",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工事略称連番",
        field: "Kouji_CD_Serial",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工事略称枝番",
        field: "kouji_edaban",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工事監理機関名",
        field: "kouji_kanri_kikan_nm",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工期終了年月日",
        field: "Kouji_Kouki_ED_DT",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "工期開始年月日",
        field: "Kouji_Kouki_ST_DT",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "工事略称カナ",
        field: "kouji_nm_kana",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "ステータス",
        field: "Kouji_Status",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "事業主名",
        field: "owner_nm",
        filterParams: distinctFilterParams,
      },
      { headerName: "PrjCD", field: "PROJCD", filter: "agTextColumnFilter" },
      {
        headerName: "支店コード",
        field: "Shiten_CD",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "現場代理人社員番号",
        field: "site_agent_cd",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "現場代理人従事期間至",
        field: "site_agent_end",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "現場代理人氏名",
        field: "site_agent_nm",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "現場代理人従事期間自",
        field: "site_agent_start",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工期始日",
        field: "start_construction_day",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "工期始月",
        field: "start_construction_month",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "工期始年",
        field: "start_construction_year",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "担当者従事期間自",
        field: "start_engagement",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "工事場所コード自",
        field: "start_location_cd",
        filterParams: distinctFilterParams,
      },
      { headerName: "SYSCODE", field: "syscode",         filterParams: distinctFilterParams,
      },
      {
        headerName: "支給材貸与品",
        field: "taiyohin",
        filterParams: distinctFilterParams,
      },
      { headerName: "URLString", field: "Url", filter: "agTextColumnFilter" },
    ],
  },
  {
    headerName: "不動産",
    children: [
      {
        headerName: "担当支店名",
        field: "Shiten_NM",
        filterParams: distinctFilterParams,
      },
      { headerName: "構造", field: "structure",         filterParams: distinctFilterParams,
      },
      {
        headerName: "面積実測",
        field: "Area_measure",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "面積公簿",
        field: "Area_Public",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "担当部署",
        field: "Busyo_NM",
        filterParams: distinctFilterParams,
      },
      { headerName: "地番", field: "Chiban", filter: "agTextColumnFilter" },
      {
        headerName: "社内向けコメント",
        field: "Comment_Inner",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "取引形態",
        field: "Form_Torihiki",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "建蔽率1",
        field: "Kenpei_Rate1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "その他住所",
        field: "Other_Address",
        filter: "agTextColumnFilter",
      },
      { headerName: "PRE分類", field: "PRE_DIV",         filterParams: distinctFilterParams,
      },
      { headerName: "価格", field: "Price", filter: "agTextColumnFilter" },
      { headerName: "ランク", field: "Rank",         filterParams: distinctFilterParams,
      },
      {
        headerName: "市区町村",
        field: "Shikutyouson",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "案件状況",
        field: "Situation_Anken",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "検討状況",
        field: "Situation_Kentou",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "開発極小種地フラグ",
        field: "Tanechi_Minimum_Flg",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "担当者名",
        field: "Tantou_NM",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "都道府県名",
        field: "Todouhuken_NM",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "容積率1",
        field: "Youseki_Rate1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "用途地域1",
        field: "Zoning1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "築月",
        field: "Build_Month",
        filter: "agTextColumnFilter",
      },
      { headerName: "築年", field: "Build_Year", filter: "agTextColumnFilter" },
      { headerName: "地目", field: "Chimoku", filter: "agTextColumnFilter" },
      {
        headerName: "地目複数欄",
        field: "Chimoku_Multi",
        filterParams: distinctFilterParams,
      },
      { headerName: "DMフラグ", field: "DM_FLG", filter: "agTextColumnFilter" },
      {
        headerName: "売貸ID",
        field: "Estate_CD",
        filter: "agTextColumnFilter",
      },
      { headerName: "階層", field: "Kaisou", filter: "agTextColumnFilter" },
      {
        headerName: "買付提示備考",
        field: "Kaituke_Remarks",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "買付提示状況",
        field: "Kaituke_Situation",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "建蔽率2",
        field: "Kenpei_Rate2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "建蔽率3",
        field: "Kenpei_Rate3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "区域区分",
        field: "Kuiki_Div",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "法規制備考",
        field: "Legal_Remarks",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "防火指定",
        field: "Limit_FireProtection",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "高度制限",
        field: "Limit_Height",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "その他制限",
        field: "Limit_Other",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "日影規制",
        field: "Limit_Shade",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "地図詳細ファイル",
        field: "MapFile_Detail",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "地図広域ファイル",
        field: "MapFile_Wide",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "入札日",
        field: "Nyusatu_DT",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "入札方式",
        field: "Nyusatu_Method",
        filterParams: distinctFilterParams,
      },
      { headerName: "その他", field: "Other", filter: "agTextColumnFilter" },
      {
        headerName: "価格範囲",
        field: "Price_Range",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "価格備考",
        field: "Price_Remarks",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "私道負担",
        field: "Shidou_Hutan",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "担当支店CD",
        field: "Shiten_CD",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "案件状況備考",
        field: "Situation_Anken_Remarks",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "情報源部署",
        field: "Source_Busyo",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "情報源会社",
        field: "Source_Company",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "情報源担当者",
        field: "Source_Tantou",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路接道1",
        field: "Surface_Road_Contact1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路接道2",
        field: "Surface_Road_Contact2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路接道3",
        field: "Surface_Road_Contact3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路接道4",
        field: "Surface_Road_Contact4",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路方角1",
        field: "Surface_Road_Direct1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路方角2",
        field: "Surface_Road_Direct2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路方角3",
        field: "Surface_Road_Direct3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路方角4",
        field: "Surface_Road_Direct4",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路区分1",
        field: "Surface_Road_Div1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路区分2",
        field: "Surface_Road_Div2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路区分3",
        field: "Surface_Road_Div3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路区分4",
        field: "Surface_Road_Div4",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路幅員1",
        field: "Surface_Road_Width1",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路幅員2",
        field: "Surface_Road_Width2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路幅員3",
        field: "Surface_Road_Width3",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "接面道路幅員4",
        field: "Surface_Road_Width4",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "収支検討備考",
        field: "Syuushi_Kentou_Remarks",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "収支検討状況",
        field: "Syuushi_Kentou_Situation",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "担当者ID",
        field: "Tantou_CD",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "建物備考",
        field: "Tatemono_Remarks",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "建物用途",
        field: "Tatemono_Youto",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "都道府県CD",
        field: "Todouhuken_CD",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "延床面積",
        field: "Total_Area",
        filterParams: distinctFilterParams,
      },
      { headerName: "交通", field: "Traffic", filter: "agTextColumnFilter" },
      {
        headerName: "容積率2",
        field: "Youseki_Rate2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "容積率3",
        field: "Youseki_Rate3",
        filterParams: distinctFilterParams,
      },
      { headerName: "地主", field: "Zinushi",         filterParams: distinctFilterParams,
      },
      {
        headerName: "地主面談備考",
        field: "Zinushi_Mendan_Remarks",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "地主面談状況",
        field: "Zinushi_Mendan_Situation",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "用途地域2",
        field: "Zoning2",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "用途地域3",
        field: "Zoning3",
        filterParams: distinctFilterParams,
      },
    ],
  },
  {
    headerName: "BCP",
    children: [
      {
        headerName: "地表面最大速度",
        field: "chihyo_sokudo",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "土砂災害警戒区域",
        field: "dosya",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "洪水浸水想定区域",
        field: "kouzui",
        filterParams: distinctFilterParams,
      },
      { headerName: "写真", field: "photo", filter: "agTextColumnFilter" },
      {
        headerName: "PML（RC造旧耐震）",
        field: "pml_rc_kyutaishin_is0_4",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "PML（RC造新耐震）",
        field: "pml_rc_shintaishin_is0_6",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "PML（S造旧耐震）",
        field: "pml_s_kyutaishin_is0_4",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "PML（S造新耐震）",
        field: "pml_s_shintaishin_is0_6",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "年間降水量",
        field: "rain",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "竜巻等の突風等",
        field: "tatsumaki",
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "津波浸水想定",
        field: "tsunami",
        filterParams: distinctFilterParams,
      },
    ],
  },
  {
    headerName: "NPS",
    children: [
      {
        headerName: "継続発注スコア",
        field: "npsscore_continuous_order",
        filterParams: distinctFilterParams,
      },
      {
        headerName: "他方推薦スコア",
        field: "npsscore_recommendation",
        filterParams: distinctFilterParams,
      },
    ],
  },
]
