
const SearchPropertyDataState = {

  initialized: false,

  isLoading: true,
  search_address: null,
  address_point: [],
  place: null,
  customer: null,

  map_point: null,
  map_point_info: null,

  area: null,
  area_info: null,

  transitIcData: null,
  transitTrainData: null,
  selectedTransitId: [],

  selectedLayer: null
}

export default SearchPropertyDataState
