export default {
    name: "津波浸水想定区域",
  attribute: {
    title: "「国土数値情報（津波浸水想定データ）」（国土交通省）",
    url: "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A40-v2_0.html",
  },
  query: "SELECT * FROM tsunami_inundation",

  css: `
#layer [inundation_depth_rank="0.01m以上 ～ 0.3m未満"] {
  polygon-fill: #cbd760;
  polygon-opacity: 0.9;
}
#layer [inundation_depth_rank="0.3m以上 ～ 1m未満"] {
  polygon-fill: #d7bd60;
  polygon-opacity: 0.9;
}
#layer [inundation_depth_rank="1m以上 ～ 2m未満"] {
  polygon-fill: #d7a960;
  polygon-opacity: 0.9;
}
#layer [inundation_depth_rank="2m以上 ～ 3m未満"] {
  polygon-fill: #d79860;
  polygon-opacity: 0.9;
}
#layer [inundation_depth_rank="3m以上 ～ 4m未満"] {
  polygon-fill: #d77a60;
  polygon-opacity: 0.9;
}
#layer [inundation_depth_rank="4m以上 ～ 5m未満"] {
  polygon-fill: #d76460;
  polygon-opacity: 0.9;
}
#layer [inundation_depth_rank="5m以上 ～ 10m未満"] {
  polygon-fill: #9c1a16;
  polygon-opacity: 0.9;
}
#layer [inundation_depth_rank="10m以上 ～ 20m未満"] {
  polygon-fill: #630502;
  polygon-opacity: 0.9;
}
#layer [inundation_depth_rank="20m以上"] {
  polygon-fill: #100000;
  polygon-opacity: 0.9;
}

#layer::outline {
  line-width: 1;
  line-color: #FFFFFF;
  line-opacity: 0.5;
} 
      `,
  legend: [
    { color: "#cbd760", label: "0.01m以上 ～ 0.3m未満" },
    { color: "#d7bd60", label: "0.3m以上 ～ 1m未満" },
    { color: "#d7a960", label: "1m以上 ～ 2m未満" },
    { color: "#d79860", label: "2m以上 ～ 3m未満" },
    { color: "#d77a60", label: "3m以上 ～ 4m未満" },
    { color: "#d76460", label: "4m以上 ～ 5m未満" },
    { color: "#9c1a16", label: "5m以上 ～ 10m未満" },
    { color: "#630502", label: "10m以上 ～ 20m未満" },
    { color: "#100000", label: "20m以上" },
  ],
}
