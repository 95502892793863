export default {
  name: "洪水浸水想定区域",
  attribute: {
    title: "「国土数値情報（洪水浸水想定区域データ）」（国土交通省）",
    url: "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A31-v2_2.html",
    sub_title:
      "※国管理河川については、2020年度データを使用したが、中国地方整備局のみ2019年度データを使用。県管理河川については、2012年から2020年にかけて作成されたもので最新のものを使用した。",
  },
  query: `
    SELECT 
      the_geom, 
      the_geom_webmercator,
      cartodb_id,
      inundation_depth_rank_code,
      inundation_depth_rank 
    FROM "${process.env.REACT_APP_CARTO_USERNAME}".inundation_area
  `,
  css: `
#layer  {
  [inundation_depth_rank_code=1]{polygon-fill: #9fe187;}
  [inundation_depth_rank_code=2]{polygon-fill: #e1cc87;}
  [inundation_depth_rank_code=3]{polygon-fill: #e19987;}
  [inundation_depth_rank_code=4]{polygon-fill: #e18787;}
  polygon-opacity: 0.4;
  line-width: 1;
  line-color: #FFFFFF;
  line-opacity: 0.5;
}
`,
  legend: [
    { color: "#F7F5A9", label: "0m以上0.5m未満" },
    { color: "#FFD8C0", label: "0.5m以上3.0m未満" },
    { color: "#FFB7B7", label: "3.0m以上5.0m未満" },
    { color: "#FE9191", label: "5.0m以上10.0m未満" },
    { color: "#F385CA", label: "10.0m以上20.0m未満" },
    { color: "#DD7ADC", label: "20.0m以上" },
  ],
}
