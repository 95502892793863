import dayjs from "dayjs"

export const filterModelToWhereStringArray = (name, filterModel) => {
  let val = filterModelToWhereString(name, filterModel)
  return val ? [val] : []
}

export const filterModelToWhereString = (name, filterModel) => {
  switch (filterModel.filterType) {
    case "text":
      return textFilterModelToWhereString(name, filterModel)
    case "number":
      return numberFilterModelToWhereString(name, filterModel)
    case "set":
      return setFilterModelToWhereString(name, filterModel)
    default:
      break
  }
  return null
}

export const numberDateFilterModelToWhereString = (name, filterModel) => {
  if (filterModel.condition1 && filterModel.condition2) {
    let c1 = numberDateFilterModelToWhereString(name, filterModel.condition1)
    let c2 = numberDateFilterModelToWhereString(name, filterModel.condition2)
    if (!c1 || !c2) {
      return null
    }
    return `(${c1} ${filterModel.operator ?? "AND"} ${c2})`
  }
  let ymd = dayjs(filterModel.dateFrom).format("YYYYMMDD")
  if (!ymd) {
    return null
  }
  let ymdTo =
    filterModel.dateTo && dayjs(filterModel.dateTo).format("YYYYMMDD")
  switch (filterModel.type) {
    case "equals":
      return `${name} = ${ymd}`
    case "notEquals":
      return `${name} <> ${ymd}`
    case "lessThan":
      return `${name} < ${ymd}`
    case "lessThanOrEqual":
      return `${name} <= ${ymd}`
    case "greaterThan":
      return `${name} > ${ymd}`
    case "greaterThanOrEqual":
      return `${name} >= ${ymd}`
    case "inRange":
      return `${name} BETWEEN ${ymd} AND ${ymdTo}`
    default:
      break
  }
  return null
}

export const setFilterModelToWhereString = (name, filterModel) => {
  if (filterModel.values.length === 0) {
    return "FALSE"
  }
  if (filterModel.values && filterModel.values.length > 0) {
    let isEmpty = false
    let values = filterModel.values
    if (values.includes("(空白・該当なし)")) {
      values.splice(values.indexOf("(空白・該当なし)"), 1)
      isEmpty = true
    }
    return (
      `(${name} IN ('${filterModel.values.join("','")}')` +
      (isEmpty ? ` OR ${name} IS NULL OR ${name} = '')` : ")")
    )
  }

  return null
}

export const numberFilterModelToWhereString = (name, filterModel) => {
  if (filterModel.condition1 && filterModel.condition2) {
    let c1 = numberFilterModelToWhereString(name, filterModel.condition1)
    let c2 = numberFilterModelToWhereString(name, filterModel.condition2)
    if (!c1 || !c2) {
      return null
    }
    return `(${c1} ${filterModel.operator ?? "AND"} ${c2})`
  }
  if (filterModel.filter.toString().indexOf("'") !== -1) {
    // シングルクォーテーションを含む場合は処理しない
    return null
  }
  switch (filterModel.type) {
    case "equals":
      return `${name} = ${filterModel.filter}`
    case "notEquals":
      return `${name} <> ${filterModel.filter}`
    case "lessThan":
      return `${name} < ${filterModel.filter}`
    case "lessThanOrEqual":
      return `${name} <= ${filterModel.filter}`
    case "greaterThan":
      return `${name} > ${filterModel.filter}`
    case "greaterThanOrEqual":
      return `${name} >= ${filterModel.filter}`
    case "inRange":
      return `${name} BETWEEN ${filterModel.filter} AND ${filterModel.filterTo}`
    default:
      break
  }
  return null
}

export const textFilterModelToWhereString = (name, filterModel) => {
  if (filterModel.condition1 && filterModel.condition2) {
    let c1 = textFilterModelToWhereString(name, filterModel.condition1)
    let c2 = textFilterModelToWhereString(name, filterModel.condition2)
    if (!c1 || !c2) {
      return null
    }
    return `(${c1} ${filterModel.operator ?? "AND"} ${c2})`
  }
  if (filterModel.filter.toString().indexOf("'") !== -1) {
    // シングルクォーテーションを含む場合は処理しない
    return null
  }
  switch (filterModel.type) {
    case "contains":
      return `${name} LIKE '%${filterModel.filter}%'`
    case "notContains":
      return `${name} NOT LIKE '%${filterModel.filter}%'`
    case "equals":
      return `${name} = '${filterModel.filter}'`
    case "notEqual":
      return `${name} <> '${filterModel.filter}'`
    case "startsWith":
      return `${name} LIKE '${filterModel.filter}%'`
    case "endsWith":
      return `${name} LIKE '%${filterModel.filter}'`
    default:
      break
  }
  return null
}
